import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { LoginService } from '../login/login.service';
import { UserService } from '../user-management/user.service';

@Component({
  selector: 'app-email-verified-successfully',
  templateUrl: './email-verified-successfully.component.html',
  styleUrls: ['./email-verified-successfully.component.css']
})
export class EmailVerifiedSuccessfullyComponent implements OnInit, OnDestroy {

  paramsObject: any;
  emailVerifyView: boolean;
  changePwdObj: any = {};
  userData: any = {};

  constructor(
    // tslint:disable-next-line: variable-name
    public _formBuilder: UntypedFormBuilder,
    readonly primengConfig: PrimeNGConfig,
    readonly loginService: LoginService,
    readonly router: Router,
    readonly route: ActivatedRoute,
    readonly toast: ToastrService,
    readonly userService: UserService,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.paramsObject = { ...params.keys, ...params };
      const token = this.paramsObject.params.token;
    });
    this.resetPassword();
  }


  resetPassword(): void {
    const id = this.paramsObject.params.token;
    this.userService.verifyAccout(id).subscribe((data: any) => {

      if (data && data.status === 200) {
        this.toast.success(data.message);
      }
      else {
        this.toast.warning(data.message);
      }
    }, (err) => {

      this.toast.warning(err.error.message);
    });
  }

  home(): void {
    localStorage.removeItem('resetToken');
    localStorage.clear();
    this.router.navigate(['/']);
  }

  ngOnDestroy(): void {
    this.resetPassword();
  }
}


