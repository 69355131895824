import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeNameService {
  readonly msgsource = new BehaviorSubject<string>('Employee Name');
  telecast = this.msgsource.asObservable();
  readonly employeeDetail = new BehaviorSubject<string>('Basic Details');
  telecastEmpDetail = this.employeeDetail.asObservable();
  readonly saveEmployeeId = new BehaviorSubject<string>(null)
  constructor() { }

  editMsg(newmsg): void {
    setTimeout(() => this.msgsource.next(newmsg), 0);
  }

  sendEmployeeId(data) {
    this.saveEmployeeId.next(data)
  }

  getEmployeeId(): Observable<any> {
    return this.saveEmployeeId.asObservable()
  }
}
