import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoaderService } from './../service/loader.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  baseUrl;

  constructor(
    readonly router: Router,
    readonly spiner: LoaderService,
    readonly toast: ToastrService
  ) {
    this.baseUrl = environment.host_api_url;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({ url: req.url });
    const token: string = localStorage.getItem('token');
    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', token)
      });
    }
    this.spiner.show();
    req = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin', '*') });
    req = req.clone({ headers: req.headers.set('Access-Control-Allow-Credentials', 'true') });
    req = req.clone({ headers: req.headers.set('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT,DELETE') });
    req = req.clone({
      headers: req.headers.set('Access-Control-Allow-Headers',
        'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers')
    });
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.spiner.hide();
          }
        },
        error => {
          if (error instanceof HttpErrorResponse && (error.status === 400) || (error.status === 500) || (error.status === 406)) {
            this.spiner.hide();
            return;
          } else if (error instanceof HttpErrorResponse && (error.status === 500)) {
            this.toast.error("Error in Application. Please Connect with the Admin")
            this.spiner.hide();
            return;
          }
          else if (error instanceof HttpErrorResponse && (error.status === 401)) {
            this.spiner.hide();
            localStorage.clear();
            this.router.navigateByUrl('/login');
          }
          this.spiner.hide();
        }));
  }
}
