import { Component, OnInit } from '@angular/core';
import { SlidePanelAnimation } from '../animation/animation';
import { AppService } from '../service/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SideShowHideService } from '../service/side-show-hide.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: SlidePanelAnimation
})

export class SidebarComponent implements OnInit {
  showChildId: number;
  myClassProp = true;
  showChildData = false;
  showslide = false;
  showslide2 = false;
  showslide3 = false;
  onloadSubscription: Subscription;
  tabSelected = false;
  id: string;
  idInner: any;
  message: string;
  valueSide: string;
  taxDetailsData: any;
  roleValueLogin: any;
  userType: string;
  roleTypeName: string;
  showDetailList: string;
  showMobileViewSideBar = false;
  showWebSideBar = false;
  constructor(
    readonly appService: AppService,
    public router: Router,
    public route: ActivatedRoute,
    readonly sidebar: SideShowHideService
  ) { }

  ngOnInit(): void {
    this.roleValueLogin = JSON.parse(localStorage.getItem('roleValueLogin'));
    this.sidebar.telecast.subscribe(message => this.message = message);
    this.valueSide = localStorage.getItem('sideBarShowHide');
    this.userType = localStorage.getItem('userType');
    this.roleTypeName = localStorage.getItem('roleTypeName');
    if (this.roleTypeName === 'Employer') {
      this.showDetailList = 'R';
    }
    else {
      this.showDetailList = 'S';
    }
    if (this.valueSide) {
      this.message = this.valueSide;
    }
    else {
      this.message = 'T';
    }
    this.onloadSubscription = this.appService.setPlanView$.subscribe((data) => {
    });
    this.loadSidebar();
    if(window.innerWidth < 1100) {
      this.showMobileViewSideBar = true;
      this.showWebSideBar = false
    } else {
      this.showWebSideBar = true;
    }
  }

  toggleSideBarmobile(){
    if(window.screen.width < 768) {
      this.showMobileViewSideBar = !this.showMobileViewSideBar;
      this.showWebSideBar = false
    } else {
      this.showWebSideBar = true;
    }
  }

  loadSidebarSplit(): void {
// tslint:disable-next-line: no-shadowed-variable
    (($) => {
      $(() => {
        $('#sidebar li.active').addClass('open').children('ul').show();
        // tslint:disable-next-line: typedef
        $('#sidebar li.sub>a').on('click', function() {
          $(this).removeAttr('href');
          const element = $(this).parent('li');
          if (element.hasClass('open') && element.hasClass('sub')) {
            element.removeClass('open');
            element.find('li').removeClass('open');
            element.find('ul').slideUp(200);
          } else {
            element.addClass('open');
            element.children('ul').slideDown(200);
            element.siblings('li').children('ul').slideUp(200);
            element.siblings('li').removeClass('open');
            element.siblings('li').find('li').removeClass('open');
            element.siblings('li').find('ul').slideUp(200);
          }
        });
      });
    })(jQuery);
  }

  loadSidebar(): void {
    // tslint:disable-next-line: typedef
    $('#sidebar > ul > li').each(function() {
      const sideItem = $(this);
      if (sideItem.find('a').attr('href') === location.pathname) {
        sideItem.addClass('activation');
      }
      if (sideItem.hasClass('sub2')) {
        sideItem.addClass('open');
        sideItem.children('ul').slideDown(200);
        sideItem.siblings('li').children('ul').slideUp(200);
        sideItem.siblings('li').removeClass('open');
        sideItem.siblings('li').find('li').removeClass('open');
        sideItem.siblings('li').find('ul').slideUp(200);
      }
    });
    this.loadSidebarSplit();
  }

  slideShow(): void {
    this.showslide = !this.showslide;
    this.appService.updatesideBarSlide(this.showslide);
  }


  ngDestroy(): void {
    if (this.onloadSubscription) {
      this.onloadSubscription.unsubscribe();
    }
  }
  
  toggleView(): void {
    this.showMobileViewSideBar = !this.showMobileViewSideBar;
    this.appService.toggleSideBar(this.showMobileViewSideBar)
  }

  checkRoutes(): void { }
}
