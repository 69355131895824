import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from '../app.constant';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(readonly http: HttpClient) { }

  gettoken(): boolean {
    return !!localStorage.getItem('token');
  }

  userDetails(loginUser, id: any): Observable<any> {
    const userFilteredData = URLS.userFilteredData + 'employerId' + '=' + id;
    return this.http.post(userFilteredData, loginUser);
  }

  saveUser(newUser): Observable<any> {
    return this.http.post(URLS.createNewUser, newUser);
  }

  payrollPrintPdf(id, data): Observable<any> {
    const payrollPrintPdf = URLS.payrollPrintPdf + `/${id}`;
    return this.http.post(payrollPrintPdf, data, { responseType: 'blob' });
  }

  updateUser(id, data): Observable<any> {
    let editUser = URLS.editUser;
    editUser = editUser.replace(':id', id);
    return this.http.put(editUser, data);
  }
  editNewAddress(id, data): Observable<any> {
    let editUser = URLS.editAddress;
    editUser = editUser.replace(':id', id);
    return this.http.post(editUser, data);
  }



  approveProcessTaxLiability(id, data): Observable<any> {
    let approveProcessTaxLiability = URLS.approveProcessTaxLiability;
    approveProcessTaxLiability = approveProcessTaxLiability.replace(':id', id);
    return this.http.post(approveProcessTaxLiability, data);
  }

  saveDirectBankAccount(id, data): Observable<any> {
    let saveDirectBankAccount = URLS.saveDirectBankAccount;
    saveDirectBankAccount = saveDirectBankAccount.replace(':id', id);
    return this.http.post(saveDirectBankAccount, data);
  }

  savePayPeriodTwiceMonth(id, data): Observable<any> {
    let savePayPeriodTwiceMonth = URLS.savePayPeriodTwiceMonth;
    savePayPeriodTwiceMonth = savePayPeriodTwiceMonth.replace(':id', id);
    return this.http.post(savePayPeriodTwiceMonth, data);
  }

  postDeductionContribution(id, data): Observable<any> {
    let postDeductionContribution = URLS.postDeductionContribution;
    postDeductionContribution = postDeductionContribution.replace(':id', id);
    return this.http.post(postDeductionContribution, data);
  }

  savePayrollSetup(data): Observable<any> {
    const savePayrollSetup = URLS.savePayrollSetup;
    return this.http.post(savePayrollSetup, data);
  }

  saveDeductionGarnishment(id, data): Observable<any> {
    let saveDeductionGarnishment = URLS.saveDeductionGarnishment;
    saveDeductionGarnishment = saveDeductionGarnishment.replace(':id', id);
    return this.http.post(saveDeductionGarnishment, data);
  }

  saveCompanyContribution(id, data): Observable<any> {
    let saveCompanyContribution = URLS.saveCompanyContribution;
    saveCompanyContribution = saveCompanyContribution.replace(':id', id);
    return this.http.post(saveCompanyContribution, data);
  }

  updateCompanyContribution(id, editId, data): Observable<any> {
    let updateCompanyContribution = URLS.updateCompanyContribution;
    updateCompanyContribution = updateCompanyContribution.replace(':id', id) + '/' + editId;
    return this.http.post(updateCompanyContribution, data);
  }

  updateSickTimeOffPolicies(id, editId, data): Observable<any> {
    let updateSickTimeOffPolicies = URLS.updateSickTimeOffPolicies;
    updateSickTimeOffPolicies = updateSickTimeOffPolicies.replace(':id', id) + '/' + editId;
    return this.http.put(updateSickTimeOffPolicies, data);
  }

  addBusinessPay(id, data): Observable<any> {
    let addBusinessPay = URLS.addBusinessPay;
    addBusinessPay = addBusinessPay.replace(':id', id);
    return this.http.post(addBusinessPay, data);
  }

  savePayrollContractor(data): Observable<any> {
    const savePayrollContractor = URLS.savePayrollContractor;
    return this.http.post(savePayrollContractor, data);
  }

  changePassword(newUser): Observable<any> {
    return this.http.put(URLS.changePassword, newUser);
  }

  twoFactorVerify(id): Observable<any> {
    const twoFactorVerify = URLS.twoFactorVerify + '?otp=' + id;
    return this.http.put(twoFactorVerify, '');
  }

  zipCodeData(id): Observable<any> {
    const zipCodeValue = URLS.getZipCode + '?stateId=' + id;
    return this.http.get(zipCodeValue);
  }

  getpaycheck(id): Observable<any> {
    let getpaycheck = URLS.getpaycheck;
    getpaycheck = getpaycheck.replace(':id', id);
    return this.http.get(getpaycheck);
  }

  getCompanyContributionById(id, editId): Observable<any> {
    let getCompanyContributionById = URLS.getCompanyContributionById;
    getCompanyContributionById = getCompanyContributionById.replace(':id', id) + '/' + editId;
    return this.http.get(getCompanyContributionById);
  }

  getPayrollDeduction(id): Observable<any> {
    let getPayrollDeduction = URLS.getPayrollDeduction;
    getPayrollDeduction = getPayrollDeduction.replace(':id', id);
    return this.http.get(getPayrollDeduction);
  }

  postPayrollDeduction(id, formData): Observable<any> {
    let postPayrollDeduction = URLS.postPayrollDeduction;
    postPayrollDeduction = postPayrollDeduction.replace(':id', id);
    return this.http.post(postPayrollDeduction, formData);
  }

  getLocalTaxName(id): Observable<any> {
    let getLocalTaxName = URLS.getLocalTaxName;
    getLocalTaxName = getLocalTaxName.replace(':id', id);
    return this.http.get(getLocalTaxName);
  }

  fetchEmployerFederaltaxNames(): Observable<any> {
    const fetchEmployerFederaltaxNames = URLS.fetchEmployerFederaltaxNames;
    return this.http.get(fetchEmployerFederaltaxNames);
  }

  getContactorDetail(id): Observable<any> {
    let getContactorDetail = URLS.getContactorDetail;
    getContactorDetail = getContactorDetail.replace(':id', id);
    return this.http.get(getContactorDetail);
  }

  getPayStubData(id): Observable<any> {
    let getPayStubData = URLS.getPayStubData;
    getPayStubData = getPayStubData.replace(':id', id);
    return this.http.get(getPayStubData);
  }

  getPayPeriodDropDown(id): Observable<any> {
    let getPayPeriodDropDown = URLS.getPayPeriodDropDown;
    getPayPeriodDropDown = getPayPeriodDropDown.replace(':id', id);
    return this.http.get(getPayPeriodDropDown);
  }

  getDeductionName(id, qid): Observable<any> {
    let getDeductionName = URLS.getDeductionName;
    getDeductionName = getDeductionName.replace(':id', id) + '/' + qid;
    return this.http.get(getDeductionName);
  }

  logout(): Observable<any> {
    const logout = URLS.logout;
    return this.http.delete(logout);
  }

  getDirectBankAccount(id): Observable<any> {
    let getDirectBankAccount = URLS.getDirectBankAccount;
    getDirectBankAccount = getDirectBankAccount.replace(':id', id);
    return this.http.get(getDirectBankAccount);
  }

  getBusinessPay(id): Observable<any> {
    let getBusinessPay = URLS.getBusinessPay;
    getBusinessPay = getBusinessPay.replace(':id', id);
    return this.http.get(getBusinessPay);
  }

  getPayrollFetchTable(id, payRange): Observable<any> {
    let getPayrollFetchTable = URLS.getPayrollFetchTable;
    getPayrollFetchTable = getPayrollFetchTable.replace(':id', id);
    return this.http.post(getPayrollFetchTable, payRange);
  }

  getSecondPayrollFetchTable(id, payRange): Observable<any> {
    let getSecondPayrollFetchTable = URLS.getSecondPayrollFetchTable;
    getSecondPayrollFetchTable = getSecondPayrollFetchTable.replace(':id', id);
    return this.http.post(getSecondPayrollFetchTable, payRange);
  }

  getTodoList(req, id): Observable<any> {
    const getTodoList = URLS.getTodoList + 'employerId' + '=' + id;
    return this.http.post(getTodoList, req);
  }

  getTodoListnew(req): Observable<any> {
    const getTodoList = URLS.getTodoList.replace('?', '')
    return this.http.post(getTodoList, req);
  }

  getPayrollSchedule(id): Observable<any> {
    let getPayrollSchedule = URLS.getPayrollSchedule;
    getPayrollSchedule = getPayrollSchedule.replace(':id', id);
    return this.http.get(getPayrollSchedule);
  }

  getBusniessAddress(id): Observable<any> {
    let getBusniessAddress = URLS.getBusniessAddress;
    getBusniessAddress = getBusniessAddress.replace(':id', id);
    return this.http.get(getBusniessAddress);
  }

  getEmployeePayrollSummary(id, data): Observable<any> {
    let getEmployeePayrollSummary = URLS.getEmployeePayrollSummary;
    getEmployeePayrollSummary = getEmployeePayrollSummary.replace(':id', id);
    return this.http.post(getEmployeePayrollSummary, data);
  }

  postDeductionName(data, id, PID): Observable<any> {
    let postDeductionName = URLS.postDeductionName;
    postDeductionName = postDeductionName + '/' + id + '/' + PID;
    return this.http.post(postDeductionName, data);
  }

  saveBusniessAddress(id, data): Observable<any> {
    let saveBusniessAddress = URLS.saveBusniessAddress;
    saveBusniessAddress = saveBusniessAddress.replace(':id', id);
    return this.http.post(saveBusniessAddress, data);
  }

  getPayDate(id, data): Observable<any> {
    let getPayDate = URLS.getPayDate;
    getPayDate = getPayDate.replace(':id', id);
    return this.http.post(getPayDate, data);
  }

  getDeductionSummary(id, data): Observable<any> {
    let getDeductionSummary = URLS.getDeductionSummary;
    getDeductionSummary = getDeductionSummary.replace(':id', id);
    return this.http.post(getDeductionSummary, data);
  }

  getPayCheckListSummary(id, data): Observable<any> {
    let getPayCheckListSummary = URLS.getPayCheckListSummary;
    getPayCheckListSummary = getPayCheckListSummary.replace(':id', id);
    return this.http.post(getPayCheckListSummary, data);
  }

  uploadKotaPay(id, data): Observable<any> {
    let uploadKotaPay = URLS.uploadKotaPay;
    uploadKotaPay = uploadKotaPay.replace(':id', id);
    return this.http.post(uploadKotaPay, data);
  }

  uploadForm8655(id, data): Observable<any> {
    let uploadForm8655 = URLS.uploadForm8655;
    uploadForm8655 = uploadForm8655.replace(':id', id);
    return this.http.post(uploadForm8655, data);
  }

  getPayrollDetailSummary(id, req): Observable<any> {
    let getPayrollDetailSummary = URLS.getPayrollDetailSummary;
    getPayrollDetailSummary = getPayrollDetailSummary.replace(':id', id);
    return this.http.post(getPayrollDetailSummary, req);
  }

  getTeamMemberDirectory(id): Observable<any> {
    let getTeamMemberDirectory = URLS.getTeamMemberDirectory;
    getTeamMemberDirectory = getTeamMemberDirectory.replace(':id', id);
    return this.http.get(getTeamMemberDirectory);
  }

  getEmployeStateTax(id): Observable<any> {
    let getEmployeStateTax = URLS.getEmployeStateTax;
    getEmployeStateTax = getEmployeStateTax.replace(':id', id);
    return this.http.get(getEmployeStateTax);
  }

  getKotayPay(id): Observable<any> {
    let getKotayPay = URLS.getKotayPay;
    getKotayPay = getKotayPay.replace(':id', id);
    return this.http.get(getKotayPay, { responseType: 'blob' });
  }

  getPayrollPrintPayCheck(id): Observable<any> {
    let getPayrollPrintPayCheck = URLS.getPayrollPrintPayCheck;
    getPayrollPrintPayCheck = getPayrollPrintPayCheck.replace(':id', id);
    return this.http.get(getPayrollPrintPayCheck, { responseType: 'blob' });
  }

  getPayrollPrint(id, pid): Observable<any> {
    let getPayrollPrint = URLS.getPayrollPrint;
    getPayrollPrint = getPayrollPrint.replace(':id', id) + `/${pid}`;
    return this.http.get(getPayrollPrint, { responseType: 'blob' });
  }

  formNYS1(id, pid): Observable<any> {
    let formNYS1 = URLS.formNYS1;
    formNYS1 = formNYS1.replace(':id', id) + `/${pid}`;
    return this.http.get(formNYS1, { responseType: 'blob' });
  }

  formNYS45(id, pid): Observable<any> {
    let formNYS45 = URLS.formNYS45;
    formNYS45 = formNYS45.replace(':id', id) + `/${pid}`;
    return this.http.get(formNYS45, { responseType: 'blob' });
  }

  getSignedForm8655Download(id): Observable<any> {
    let getSignedForm8655Download = URLS.getSignedForm8655Download;
    getSignedForm8655Download = getSignedForm8655Download.replace(':id', id);
    return this.http.get(getSignedForm8655Download, { responseType: 'blob' });
  }

  getSignedKotaPayDownload(id): Observable<any> {
    let getSignedKotaPayDownload = URLS.getSignedKotaPayDownload;
    getSignedKotaPayDownload = getSignedKotaPayDownload.replace(':id', id);
    return this.http.get(getSignedKotaPayDownload, { responseType: 'blob' });
  }

  getForm8655(id): Observable<any> {
    let getForm8655 = URLS.getForm8655;
    getForm8655 = getForm8655.replace(':id', id);
    return this.http.get(getForm8655, { responseType: 'blob' });
  }

  getBillingDetail(data): Observable<any> {
    const getBillingDetail = URLS.getBillingDetail;
    return this.http.post(getBillingDetail, data);
  }

  getContractorDetail(id): Observable<any> {
    let getContractorDetail = URLS.getContractorDetail;
    getContractorDetail = getContractorDetail.replace(':id', id);
    return this.http.get(getContractorDetail);
  }

  getDirectDepositByEmployerId(id): Observable<any> {
    let getDirectDepositByEmployerId = URLS.getDirectDepositByEmployerId;
    getDirectDepositByEmployerId = getDirectDepositByEmployerId.replace(':id', id);
    return this.http.get(getDirectDepositByEmployerId);
  }

  getEmployeeDeatils(id): Observable<any> {
    let getEmployeeDeatils = URLS.getEmployeeDeatils;
    getEmployeeDeatils = getEmployeeDeatils.replace(':id', id);
    return this.http.get(getEmployeeDeatils);
  }

  getContractorPayMentSummary(id, data): Observable<any> {
    let getContractorPayMentSummary = URLS.getContractorPayMentSummary;
    getContractorPayMentSummary = getContractorPayMentSummary.replace(':id', id);
    return this.http.post(getContractorPayMentSummary, data);
  }

  getContractorPaySummary(id, data): Observable<any> {
    let getContractorPaySummary = URLS.getContractorPaySummary;
    getContractorPaySummary = getContractorPaySummary.replace(':id', id);
    return this.http.post(getContractorPaySummary, data);
  }

  postStateTaxNameEmployer(id, data): Observable<any> {
    let postStateTaxNameEmployer = URLS.postStateTaxNameEmployer;
    postStateTaxNameEmployer = postStateTaxNameEmployer.replace(':id', id);
    return this.http.post(postStateTaxNameEmployer, data);
  }


  saveRolePolicy(req): Observable<any> {
    const saveRolePolicy = URLS.saveRolePolicy;
    return this.http.put(saveRolePolicy, req);
  }

  getTaxliability(id, req): Observable<any> {
    let getTaxliability = URLS.getTaxliability;
    getTaxliability = getTaxliability.replace(':id', id);
    return this.http.post(getTaxliability, req);
  }

  getTaxPayment(id, req): Observable<any> {
    let getTaxPayment = URLS.getTaxPayment;
    getTaxPayment = getTaxPayment.replace(':id', id);
    return this.http.post(getTaxPayment, req);
  }

  getTotalCost(id, req): Observable<any> {
    let getTotalCost = URLS.getTotalCost;
    getTotalCost = getTotalCost.replace(':id', id);
    return this.http.post(getTotalCost, req);
  }

  getAllTotalCostReport(id, req): Observable<any> {
    let getAllTotalCostReport = URLS.getAllTotalCostReport;
    getAllTotalCostReport = getAllTotalCostReport.replace(':id', id);
    return this.http.post(getAllTotalCostReport, req);
  }

  getRetirementPlan(id, req): Observable<any> {
    let getRetirementPlan = URLS.getRetirementPlan;
    getRetirementPlan = getRetirementPlan.replace(':id', id);
    return this.http.post(getRetirementPlan, req);
  }

  getWagesSummary(id, req): Observable<any> {
    let getWagesSummary = URLS.getWagesSummary;
    getWagesSummary = getWagesSummary.replace(':id', id);
    return this.http.post(getWagesSummary, req);
  }

  getSickVacation(id): Observable<any> {
    let getSickVacation = URLS.getSickVacation;
    getSickVacation = getSickVacation.replace(':id', id);
    return this.http.get(getSickVacation);
  }

  postPaycheck(id): Observable<any> {
    let postPaycheck = URLS.postPaycheck;
    postPaycheck = postPaycheck.replace(':id', id);
    return this.http.post(postPaycheck, '');
  }

  getWorkerCompensation(id, req): Observable<any> {
    let getWorkerCompensation = URLS.getWorkerCompensation;
    getWorkerCompensation = getWorkerCompensation.replace(':id', id);
    return this.http.post(getWorkerCompensation, req);
  }

  getDashboard(id): Observable<any> {
    let getDashboard = URLS.getDashboard;
    getDashboard = getDashboard.replace(':id', id);
    return this.http.get(getDashboard);
  }

  achDownloadFile(id): Observable<any> {
    let achDownloadFile = URLS.achDownloadFile;
    achDownloadFile = achDownloadFile.replace(':id', id);
    return this.http.get(achDownloadFile, { responseType: 'blob' });
  }

  getDashboardPieChart(id): Observable<any> {
    let getDashboardPieChart = URLS.getDashboardPieChart;
    getDashboardPieChart = getDashboardPieChart.replace(':id', id);
    return this.http.get(getDashboardPieChart);
  }

  employerPolicy(id: any): Observable<any> {
    const employerPolicy = URLS.employerPolicy + '?' + 'employerId' + '=' + id;
    return this.http.get(employerPolicy);
  }

  getUserById(id): Observable<any> {
    return this.http.get(URLS.getUserId + `/${id}`);
  }

  getBusinessList(id): Observable<any> {
    let getBusinessList = URLS.getBusinessList;
    getBusinessList = getBusinessList.replace(':id', id);
    return this.http.get(getBusinessList);
  }

  getEmployeePayrollSummaryExcel(id, req): Observable<any> {
    let getEmployeePayrollSummaryExcel = URLS.getEmployeePayrollSummaryExcel;
    getEmployeePayrollSummaryExcel = getEmployeePayrollSummaryExcel.replace(':id', id);
    return this.http.post(getEmployeePayrollSummaryExcel, req, { responseType: 'blob' });
  }

  getTaxPaymentExcel(id, req): Observable<any> {
    let getTaxPaymentExcel = URLS.getTaxPaymentExcel;
    getTaxPaymentExcel = getTaxPaymentExcel.replace(':id', id);
    return this.http.post(getTaxPaymentExcel, req, { responseType: 'blob' });
  }

  getPayCheckListExcel(id, req): Observable<any> {
    let getPayCheckListExcel = URLS.getPayCheckListExcel;
    getPayCheckListExcel = getPayCheckListExcel.replace(':id', id);
    return this.http.post(getPayCheckListExcel, req, { responseType: 'blob' });
  }

  getDeductionContributionExcel(id, req): Observable<any> {
    let getDeductionContributionExcel = URLS.getDeductionContributionExcel;
    getDeductionContributionExcel = getDeductionContributionExcel.replace(':id', id);
    return this.http.post(getDeductionContributionExcel, req, { responseType: 'blob' });
  }

  getBillingExcel(req): Observable<any> {
    const getBillingExcel = URLS.getBillingExcel;
    return this.http.post(getBillingExcel, req, { responseType: 'blob' });
  }

  getTeamMemberDirectoryPdf(req): Observable<any> {
    const getTeamMemberDirectoryPdf = URLS.getTeamMemberDirectoryPdf;
    return this.http.post(getTeamMemberDirectoryPdf, req, { responseType: 'blob' });
  }

  downloadBillingPdf(req): Observable<any> {
    const downloadBillingPdf = URLS.downloadBillingPdf;
    return this.http.post(downloadBillingPdf, req, { responseType: 'blob' });
  }

  getTeamMemberExcel(req): Observable<any> {
    const getTeamMemberExcel = URLS.getTeamMemberExcel;
    return this.http.post(getTeamMemberExcel, req, { responseType: 'blob' });
  }

  getContractorPaymentListExcel(id, req): Observable<any> {
    let getContractorPaymentListExcel = URLS.getContractorPaymentListExcel;
    getContractorPaymentListExcel = getContractorPaymentListExcel.replace(':id', id);
    return this.http.post(getContractorPaymentListExcel, req, { responseType: 'blob' });
  }

  getTaxWagesExcel(id, req): Observable<any> {
    let getTaxWagesExcel = URLS.getTaxWagesExcel;
    getTaxWagesExcel = getTaxWagesExcel.replace(':id', id);
    return this.http.post(getTaxWagesExcel, req, { responseType: 'blob' });
  }

  getRetirementPlanExcel(id, req): Observable<any> {
    let getRetirementPlanExcel = URLS.getRetirementPlanExcel;
    getRetirementPlanExcel = getRetirementPlanExcel.replace(':id', id);
    return this.http.post(getRetirementPlanExcel, req, { responseType: 'blob' });
  }

  sickVacationExcel(id, req): Observable<any> {
    let sickVacationExcel = URLS.sickVacationExcel;
    sickVacationExcel = sickVacationExcel.replace(':id', id);
    return this.http.post(sickVacationExcel, req, { responseType: 'blob' });
  }

  getTotalCostReportExcel(id, req): Observable<any> {
    let getTotalCostReportExcel = URLS.getTotalCostReportExcel;
    getTotalCostReportExcel = getTotalCostReportExcel.replace(':id', id);
    return this.http.post(getTotalCostReportExcel, req, { responseType: 'blob' });
  }

  getNewTotalCostReportExcel(id, req): Observable<any> {
    let getNewTotalCostReportExcel = URLS.getNewTotalCostReportExcel;
    getNewTotalCostReportExcel = getNewTotalCostReportExcel.replace(':id', id);
    return this.http.post(getNewTotalCostReportExcel, req, { responseType: 'blob' });
  }

  getEmployeeDedsAndContrPdf(id, req): Observable<any> {
    let getEmployeeDedsAndContrPdf = URLS.getEmployeeDedsAndContrPdf;
    getEmployeeDedsAndContrPdf = getEmployeeDedsAndContrPdf.replace(':id', id);
    return this.http.post(getEmployeeDedsAndContrPdf, req, { responseType: 'blob' });
  }

  downloadExcelWorkCompensation(id, req): Observable<any> {
    let downloadExcelWorkCompensation = URLS.downloadExcelWorkCompensation;
    downloadExcelWorkCompensation = downloadExcelWorkCompensation.replace(':id', id);
    return this.http.post(downloadExcelWorkCompensation, req, { responseType: 'blob' });
  }

  downloadPDFWorkCompensation(id, req): Observable<any> {
    let downloadPDFWorkCompensation = URLS.downloadPDFWorkCompensation;
    downloadPDFWorkCompensation = downloadPDFWorkCompensation.replace(':id', id);
    return this.http.post(downloadPDFWorkCompensation, req, { responseType: 'blob' });
  }

  getContractorPaySummaryExcel(id, req): Observable<any> {
    let getContractorPaySummaryExcel = URLS.getContractorPaySummaryExcel;
    getContractorPaySummaryExcel = getContractorPaySummaryExcel.replace(':id', id);
    return this.http.post(getContractorPaySummaryExcel, req, { responseType: 'blob' });
  }

  downloadAchFileTax(id, req): Observable<any> {
    let downloadAchFileTax = URLS.downloadAchFileTax;
    downloadAchFileTax = downloadAchFileTax.replace(':id', id);
    return this.http.post(downloadAchFileTax, req, { responseType: 'blob' });
  }

  approveTaxPaymentManually(id, req): Observable<any> {
    let approveTaxPaymentManually = URLS.approveTaxPaymentManually;
    approveTaxPaymentManually = approveTaxPaymentManually.replace(':id', id);
    return this.http.post(approveTaxPaymentManually, req);
  }

  getEmployeePayrollDetailPdf(id, req): Observable<any> {
    let getEmployeePayrollDetailPdf = URLS.getEmployeePayrollDetailPdf;
    getEmployeePayrollDetailPdf = getEmployeePayrollDetailPdf.replace(':id', id);
    return this.http.post(getEmployeePayrollDetailPdf, req, { responseType: 'blob' });
  }

  getEmployeePayrollSummaryPdf(id, req): Observable<any> {
    let getEmployeePayrollSummaryPdf = URLS.getEmployeePayrollSummaryPdf;
    getEmployeePayrollSummaryPdf = getEmployeePayrollSummaryPdf.replace(':id', id);
    return this.http.post(getEmployeePayrollSummaryPdf, req, { responseType: 'blob' });
  }

  getRetirementPlansPdf(id, req): Observable<any> {
    let getRetirementPlansPdf = URLS.getRetirementPlansPdf;
    getRetirementPlansPdf = getRetirementPlansPdf.replace(':id', id);
    return this.http.post(getRetirementPlansPdf, req, { responseType: 'blob' });
  }

  getVacationAndSickLeavePdf(id, req): Observable<any> {
    let getVacationAndSickLeavePdf = URLS.getVacationAndSickLeavePdf;
    getVacationAndSickLeavePdf = getVacationAndSickLeavePdf.replace(':id', id);
    return this.http.post(getVacationAndSickLeavePdf, req, { responseType: 'blob' });
  }

  getEmployerTaxAndWageSummaryPdf(id, req): Observable<any> {
    let getEmployerTaxAndWageSummaryPdf = URLS.getEmployerTaxAndWageSummaryPdf;
    getEmployerTaxAndWageSummaryPdf = getEmployerTaxAndWageSummaryPdf.replace(':id', id);
    return this.http.post(getEmployerTaxAndWageSummaryPdf, req, { responseType: 'blob' });
  }

  getEmployerTaxLiabilityPdf(id, req): Observable<any> {
    let getEmployerTaxLiabilityPdf = URLS.getEmployerTaxLiabilityPdf;
    getEmployerTaxLiabilityPdf = getEmployerTaxLiabilityPdf.replace(':id', id);
    return this.http.post(getEmployerTaxLiabilityPdf, req, { responseType: 'blob' });
  }

  getTaxPaymentPdf(id, req): Observable<any> {
    let getTaxPaymentPdf = URLS.getTaxPaymentPdf;
    getTaxPaymentPdf = getTaxPaymentPdf.replace(':id', id);
    return this.http.post(getTaxPaymentPdf, req, { responseType: 'blob' });
  }

  getTotalCostReportPdf(id, req): Observable<any> {
    let getTotalCostReportPdf = URLS.getTotalCostReportPdf;
    getTotalCostReportPdf = getTotalCostReportPdf.replace(':id', id);
    return this.http.post(getTotalCostReportPdf, req, { responseType: 'blob' });
  }

  getNewTotalCostReportPdf(id, req){
    let getNewTotalCostReportPdf = URLS.getNewTotalCostReportPdf;
    getNewTotalCostReportPdf = getNewTotalCostReportPdf.replace(':id', id);
    return this.http.post(getNewTotalCostReportPdf, req, { responseType: 'blob' });
  }

  getContractorTaxPaymentPdf(id, req): Observable<any> {
    let getContractorTaxPaymentPdf = URLS.getContractorTaxPaymentPdf;
    getContractorTaxPaymentPdf = getContractorTaxPaymentPdf.replace(':id', id);
    return this.http.post(getContractorTaxPaymentPdf, req, { responseType: 'blob' });
  }

  getContractrPaySummaryPDF(id, req): Observable<any> {
    let getContractrPaySummaryPDF = URLS.getContractrPaySummaryPDF;
    getContractrPaySummaryPDF = getContractrPaySummaryPDF.replace(':id', id);
    return this.http.post(getContractrPaySummaryPDF, req, { responseType: 'blob' });
  }

  getEmployeePaycheckListPdf(id, req): Observable<any> {
    let getEmployeePaycheckListPdf = URLS.getEmployeePaycheckListPdf;
    getEmployeePaycheckListPdf = getEmployeePaycheckListPdf.replace(':id', id);
    return this.http.post(getEmployeePaycheckListPdf, req, { responseType: 'blob' });
  }

  getEmployeePayrollDetailExcel(id, req): Observable<any> {
    let getEmployeePayrollDetailExcel = URLS.getEmployeePayrollDetailExcel;
    getEmployeePayrollDetailExcel = getEmployeePayrollDetailExcel.replace(':id', id);
    return this.http.post(getEmployeePayrollDetailExcel, req, { responseType: 'blob' });
  }

  getEmployeeTaxLiabilityExcel(id, req): Observable<any> {
    let getEmployeeTaxLiabilityExcel = URLS.getEmployeeTaxLiabilityExcel;
    getEmployeeTaxLiabilityExcel = getEmployeeTaxLiabilityExcel.replace(':id', id);
    return this.http.post(getEmployeeTaxLiabilityExcel, req, { responseType: 'blob' });
  }

  achcalendar(): Observable<any> {
    const achcalendar = URLS.achcalendar;
    return this.http.get(achcalendar);
  }

  getBusinessDetail(id): Observable<any> {
    let getBusinessDetail = URLS.getBusinessDetail;
    getBusinessDetail = getBusinessDetail.replace(':id', id);
    return this.http.get(getBusinessDetail);
  }

  editContractor(id): Observable<any> {
    let editContractor = URLS.editContractor;
    editContractor = editContractor.replace(':id', id);
    return this.http.get(editContractor);
  }

  getTurnOffPolicy(id): Observable<any> {
    let getTurnOffPolicy = URLS.getTurnOffPolicy;
    getTurnOffPolicy = getTurnOffPolicy.replace(':id', id);
    return this.http.get(getTurnOffPolicy);
  }

  getTurnOffPolicyById(id): Observable<any> {
    let getTurnOffPolicyById = URLS.getTurnOffPolicyById;
    getTurnOffPolicyById = getTurnOffPolicyById.replace(':id', id);
    return this.http.get(getTurnOffPolicyById);
  }

  getfrequency(): Observable<any> {
    const getfrequency = URLS.getfrequency;
    return this.http.get(getfrequency);
  }

  getCategoryList(): Observable<any> {
    const getCategoryList = URLS.getCategoryList;
    return this.http.get(getCategoryList);
  }

  postTurnOffPolicy(id, formData): Observable<any> {
    let postTurnOffPolicy = URLS.postTurnOffPolicy;
    postTurnOffPolicy = postTurnOffPolicy.replace(':id', id);
    return this.http.post(postTurnOffPolicy, formData);
  }

  postPaySchedule(id, formData): Observable<any> {
    let postPaySchedule = URLS.postPaySchedule;
    postPaySchedule = postPaySchedule.replace(':id', id);
    return this.http.post(postPaySchedule, formData);
  }

  savePaySchedule(id, formData): Observable<any> {
    let savePaySchedule = URLS.savePaySchedule;
    savePaySchedule = savePaySchedule.replace(':id', id);
    return this.http.post(savePaySchedule, formData);
  }

  editEmployeeList(id): Observable<any> {
    let editEmployeeList = URLS.editEmployeeList;
    editEmployeeList = editEmployeeList.replace(':id', id);
    return this.http.get(editEmployeeList);
  }

  getGarishmentContribution(id): Observable<any> {
    let getGarishmentContribution = URLS.getGarishmentContribution;
    getGarishmentContribution = getGarishmentContribution.replace(':id', id);
    return this.http.get(getGarishmentContribution);
  }

  getTaxName(id): Observable<any> {
    let getTaxName = URLS.getTaxName;
    getTaxName = getTaxName.replace(':id', id);
    return this.http.get(getTaxName);
  }

  getStateTaxName(id): Observable<any> {
    let getStateTaxName = URLS.getStateTaxName;
    getStateTaxName = getStateTaxName.replace(':id', id);
    return this.http.get(getStateTaxName);
  }

  getTaxCalculation(id): Observable<any> {
    let getTaxCalculation = URLS.getTaxCalculation;
    getTaxCalculation = getTaxCalculation.replace(':id', id);
    return this.http.get(getTaxCalculation);
  }

  getGarishmentDeduction(id): Observable<any> {
    let getGarishmentDeduction = URLS.getGarishmentDeduction;
    getGarishmentDeduction = getGarishmentDeduction.replace(':id', id);
    return this.http.get(getGarishmentDeduction);
  }

  getPayScheduleById(id): Observable<any> {
    let getPayScheduleById = URLS.getPayScheduleById;
    getPayScheduleById = getPayScheduleById.replace(':id', id);
    return this.http.get(getPayScheduleById);
  }

  getDeductionContributions(id): Observable<any> {
    let getDeductionContributions = URLS.getDeductionContributions;
    getDeductionContributions = getDeductionContributions.replace(':id', id);
    return this.http.get(getDeductionContributions);
  }

  getfetchCategoryListForDedsAndContr(): Observable<any> {
    const getfetchCategoryListForDedsAndContr = URLS.getfetchCategoryListForDedsAndContr;
    return this.http.get(getfetchCategoryListForDedsAndContr);
  }

  getSchedule(id): Observable<any> {
    let getSchedule = URLS.getSchedule;
    getSchedule = getSchedule.replace(':id', id);
    return this.http.get(getSchedule);
  }

  getDeductionContributionById(id): Observable<any> {
    let getDeductionContributionById = URLS.getDeductionContributionById;
    getDeductionContributionById = getDeductionContributionById.replace(':id', id);
    return this.http.get(getDeductionContributionById);
  }

  deleteTurnOffPolicyById(id): Observable<any> {
    let deleteTurnOffPolicyById = URLS.deleteTurnOffPolicyById;
    deleteTurnOffPolicyById = deleteTurnOffPolicyById.replace(':id', id);
    return this.http.delete(deleteTurnOffPolicyById);
  }

  deleteBusniessAddress(id): Observable<any> {
    let deleteBusniessAddress = URLS.deleteBusniessAddress;
    deleteBusniessAddress = deleteBusniessAddress.replace(':id', id);
    return this.http.delete(deleteBusniessAddress);
  }

  getCompanyContribution(id): Observable<any> {
    let getCompanyContribution = URLS.getCompanyContribution;
    getCompanyContribution = getCompanyContribution.replace(':id', id);
    return this.http.get(getCompanyContribution);
  }

  getEditGarnishment(id, editId): Observable<any> {
    let getEditGarnishment = URLS.getEditGarnishment;
    getEditGarnishment = getEditGarnishment.replace(':id', id) + '/' + editId;
    return this.http.get(getEditGarnishment);
  }

  getAllEditSickLeave(id, editId): Observable<any> {
    let getAllEditSickLeave = URLS.getAllEditSickLeave;
    getAllEditSickLeave = getAllEditSickLeave.replace(':id', id) + '/' + editId;
    return this.http.get(getAllEditSickLeave);
  }

  getSickTimeOffPolicies(id): Observable<any> {
    let getSickTimeOffPolicies = URLS.getSickTimeOffPolicies;
    getSickTimeOffPolicies = getSickTimeOffPolicies.replace(':id', id) + '?' + 'categories=' + 'Vacation' + '&' + 'categories=' + 'Sick';
    return this.http.get(getSickTimeOffPolicies);
  }

  getCompanyContributionDropdown(): Observable<any> {
    const getCompanyContributionDropdown = URLS.getCompanyContributionDropdown;
    return this.http.get(getCompanyContributionDropdown);
  }

  getCompanyContributionDropdownType(): Observable<any> {
    const getCompanyContributionDropdownType = URLS.getCompanyContributionDropdownType;
    return this.http.get(getCompanyContributionDropdownType);
  }

  deleteGarnishment(id, ids): Observable<any> {
    let saveGarnishment = URLS.saveGarnishment;
    saveGarnishment = saveGarnishment.replace(':id', id) + '/' + ids;
    return this.http.delete(saveGarnishment);
  }


  deleteApprovePaycheck(id, isDeleted?: boolean): Observable<any> {
    let deleteApprovePaycheck = URLS.deleteApprovePaycheck;
    deleteApprovePaycheck = deleteApprovePaycheck.replace(":id", id);
    return this.http.delete(`${deleteApprovePaycheck}?isDeleted=${isDeleted}`);
  }

  deleteGarishmentDeduction(id): Observable<any> {
    let deleteGarishmentDeduction = URLS.deleteGarishmentDeduction;
    deleteGarishmentDeduction = deleteGarishmentDeduction.replace(':id', id);
    return this.http.delete(deleteGarishmentDeduction);
  }

  updateGarnishment(id, editId, form): Observable<any> {
    let saveGarnishment = URLS.saveGarnishment;
    saveGarnishment = saveGarnishment.replace(':id', id) + '/' + editId;
    return this.http.put(saveGarnishment, form);
  }

  deleteGarishmentContribution(id, editId): Observable<any> {
    let deleteGarishmentContribution = URLS.deleteGarishmentContribution;
    deleteGarishmentContribution = deleteGarishmentContribution.replace(':id', id) + '/' + editId;
    return this.http.delete(deleteGarishmentContribution);
  }

  deleteAllSickTimeOffPolicies(id, editId): Observable<any> {
    let deleteAllSickTimeOffPolicies = URLS.deleteAllSickTimeOffPolicies;
    deleteAllSickTimeOffPolicies = deleteAllSickTimeOffPolicies.replace(':id', id) + '/' + editId;
    return this.http.delete(deleteAllSickTimeOffPolicies);
  }

  deleteDeductionContribution(id): Observable<any> {
    let deleteDeductionContribution = URLS.deleteDeductionContribution;
    deleteDeductionContribution = deleteDeductionContribution.replace(':id', id);
    return this.http.delete(deleteDeductionContribution);
  }

  deletePaySchedule(id): Observable<any> {
    let deletePaySchedule = URLS.deletePaySchedule;
    deletePaySchedule = deletePaySchedule.replace(':id', id);
    return this.http.delete(deletePaySchedule);
  }

  deleteDynamicPay(id): Observable<any> {
    let deleteDynamicPaySchedule = URLS.deleteDynamicPaySchedule;
    deleteDynamicPaySchedule = deleteDynamicPaySchedule.replace(':id', id);
    return this.http.delete(deleteDynamicPaySchedule);
  }

  getPrintPaycheck(id, form): Observable<any> {
    let getPrintPaycheck = URLS.getPrintPaycheck;
    getPrintPaycheck = getPrintPaycheck.replace(':id', id);
    return this.http.post(getPrintPaycheck, form);
  }

  editQuarterDetail(id): Observable<any> {
    let editQuarterDetail = URLS.editQuarterDetail;
    editQuarterDetail = editQuarterDetail.replace(':id', id);
    return this.http.get(editQuarterDetail);
  }

  updateUserStatus(id, status): Observable<any> {
    let statusUsers = URLS.statusUsers;
    statusUsers = statusUsers.replace(':id', id).replace(':status', status);
    return this.http.put(statusUsers, id, status);
  }

  updateTeamMemberStatus(id,enable): Observable<any> {
    let updateTeamMemberStatus = URLS.updateTeamMemberStatus;
    updateTeamMemberStatus = updateTeamMemberStatus.replace(':id', id).replace(':enable', enable);
    return this.http.put(updateTeamMemberStatus, id, enable);
  }

  userRoles(loginUser): Observable<any> {
    return this.http.post(URLS.userRoles, loginUser);
  }

  w2employee(id, form): Observable<any> {
    let w2employee = URLS.w2employee;
    w2employee = w2employee.replace(':id', id);
    return this.http.post(w2employee, form);
  }

  saveGarnishment(id, form): Observable<any> {
    let saveGarnishment = URLS.saveGarnishment;
    saveGarnishment = saveGarnishment.replace(':id', id);
    return this.http.post(saveGarnishment, form);
  }

  saveSickTimeOffPolicies(id, form): Observable<any> {
    let saveSickTimeOffPolicies = URLS.saveSickTimeOffPolicies;
    saveSickTimeOffPolicies = saveSickTimeOffPolicies.replace(':id', id);
    return this.http.post(saveSickTimeOffPolicies, form);
  }

  saveDynamicValue(id, form): Observable<any> {
    let saveDynamicValue = URLS.saveDynamicValue;
    saveDynamicValue = saveDynamicValue.replace(':id', id);
    return this.http.post(saveDynamicValue, form);
  }

  updateDynamicValue(form): Observable<any> {
    const updateDynamicValue = URLS.updateDynamicValue;
    return this.http.put(updateDynamicValue, form);
  }

  primaryAddress(id, addressId): Observable<any> {
    let primaryAddress = URLS.primaryAddress;
    primaryAddress = primaryAddress.replace(':id', id) + `/${addressId}`;
    return this.http.post(primaryAddress, '');
  }

  savePayPeriodMonth(id, form): Observable<any> {
    let savePayPeriodMonth = URLS.savePayPeriodMonth;
    savePayPeriodMonth = savePayPeriodMonth.replace(':id', id);
    return this.http.post(savePayPeriodMonth, form);
  }

  postPayrollPrintPayCheck(id, form): Observable<any> {
    let postPayrollPrintPayCheck = URLS.postPayrollPrintPayCheck;
    postPayrollPrintPayCheck = postPayrollPrintPayCheck.replace(':id', id);
    return this.http.post(postPayrollPrintPayCheck, form);
  }

  createNewRole(data): Observable<any> {
    return this.http.post(URLS.createNewRole, data);
  }

  searchUsers(data, id): Observable<any> {
    const userFilteredData = URLS.searchUser + 'employerId' + '=' + id;
    return this.http.post(userFilteredData, data);
  }

  searchRole(data): Observable<any> {
    return this.http.post(URLS.userRoles, data);
  }

  deleteRoles(id): Observable<any> {
    return this.http.delete(URLS.deleteRoles + `/${id}`);
  }

  updateRole(id, data): Observable<any> {
    let updateRole = URLS.updateRole;
    updateRole = updateRole.replace(':id', id);
    return this.http.put(updateRole, data);
  }

  getRoleId(id): Observable<any> {
    let updateRole = URLS.updateRole;
    updateRole = updateRole.replace(':id', id);
    return this.http.get(updateRole, id);
  }

  getGarnishment(id): Observable<any> {
    let getGarnishment = URLS.getGarnishment;
    getGarnishment = getGarnishment.replace(':id', id);
    return this.http.get(getGarnishment, id);
  }

  getAllSickTimeOffPolicies(id): Observable<any> {
    let getAllSickTimeOffPolicies = URLS.getAllSickTimeOffPolicies;
    getAllSickTimeOffPolicies = getAllSickTimeOffPolicies.replace(':id', id);
    return this.http.get(getAllSickTimeOffPolicies, id);
  }

  google2FaImage(id): Observable<any> {
    const google2FaImage = URLS.google2FaImage + '?isGoogle2FAEnabled=' + id;
    return this.http.post(google2FaImage, '');
  }

  verifyAccout(id): Observable<any> {
    const verifyAccout = URLS.verifyAccout + '?token=' + id;
    return this.http.get(verifyAccout);
  }

  getDistListType(id): Observable<any> {
    const getDistListType = URLS.getDistListType + '?category=' + id;
    return this.http.get(getDistListType);
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(URLS.deleteUsers + `/${id}`);
  }

  userPermission(): Observable<any> {
    return this.http.get(URLS.userPermission);
  }

  deleteTax(id): Observable<any> {
    const url = URLS.deleteTax.replace(':taxId', id);
    return this.http.delete(url);
  }

  downLoadPayment(id, data): Observable<any> {
    const url = URLS.downloadAchFileTax.replace(':id', id);
    return this.http.post(url, data, { responseType: 'blob' });
  }

  workerClassCompansiation(id, request): Observable<any> {
    const url = URLS.workerClassCompansiation.replace(':id', id);
    return this.http.post(url, request);
  }

  deleteMultiPaycheck(data): Observable<any> {
    return this.http.post(URLS.delePaychecks, data);
  }

  approveMultiPaycheck(request): Observable<any> {
    return this.http.post(URLS.multipleManual, request);
  }

  getFooterData(): Observable<any> {
    return this.http.get(URLS.appVersion);
  }

  getModulePermission(id): Observable<any> {
    return this.http.get(`${URLS.modulePermission}/${id}`);
  }

  updateModulePermission(request): Observable<any> {
    return this.http.post(URLS.modulePermission, request);
  }

  updateFieldPermission(request): Observable<any> {
    return this.http.post(URLS.fieldPermission, request);
  }
}
