import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutoLogOffService } from '../service/auto-logoff.service';

@Component({
  selector: 'app-inactive',
  templateUrl: './inactive.component.html',
  styleUrls: ['./inactive.component.css']
})
export class InactiveComponent implements OnInit {

  constructor(
    private router: Router,
    public autoLogOffService: AutoLogOffService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.logout();
    }, 7000);
  }

  logout(): void {
    this.autoLogOffService.isInactive.next(false);
    this.router.navigate(['/login']);
  }
}
