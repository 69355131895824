<div class="nav-sidebar" [ngClass]="{'mobile-view': showMobileViewSideBar}">
    <!-- <i *ngIf="!showMobileViewSideBar" (click)="toggleView()" 
    class="fa fa-times" aria-hidden="true" style="margin: 10px;color: #656565;position: fixed;
    margin-left: 14px;font-size: 20px;"></i> -->

    <!-- <i (click)="toggleView()" class="fa fa-arrow-circle-left" 
    aria-hidden="true" style="left: -20px;position: fixed;" [ngClass]="{'left-arrow': !showMobileViewSideBar}"></i> -->

    <!-- <i (click)="toggleView()" 
    style="margin: 10px;color: #656565;position: fixed;margin-left: 14px;font-size: 20px;" 
    class="fa fa-bars" aria-hidden="true"></i> -->

    <div *ngIf="showMobileViewSideBar" class="mt-4 ml-2 mobile-size" ml style="height: 50px;width: 30px;">
        <img style="height: 100%;" src="assets/serviceOption.jpg" alt="">
    </div>
    <i *ngIf="showMobileViewSideBar" (click)="toggleView()"
        style="margin: 10px;color: #656565;position: fixed;margin-left: 14px;" class="fa fa-bars mobile-hamburger"
        aria-hidden="true"></i>
    <div *ngIf="!showMobileViewSideBar" class="logo mt25">
        <img alt="" src="" *ngIf="!showslide" />
        <span class="side-arrow">
            <span *ngIf="!showslide"><img src="../../assets/images/logo.png" width="100%" alt="No Image" /></span>
            <em class="fas fa-chevron-right" *ngIf="showslide"></em>
        </span>
        <i (click)="toggleView()" style="margin: 10px;color: #656565;position: fixed;margin-left: 14px;font-size: 30px;"
            class="fa fa-bars hamburger-menu" aria-hidden="true"></i>
    </div>

    <div class="side-nav" [ngClass]="{'hide-nav': showMobileViewSideBar}">
        <div id="sidebar" style="margin-top: 7%;  width: 220px;
        height: 100vh;
        height: 100%;
        z-index: 99;
        position: fixed;
        left: 0;
        transition: all .5s ease!important;
        text-align: left;
        font-size: 15px;
        Letter-spacing: 0px; 
        color: #3b4041;
        padding-bottom: 35px !important;
        height: calc(100% - 480px);
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 0%;">
            <ul>
                <!-- <li><a href='#'>Sidebar Menu</a></li> -->
                <li class='sub sub2'><a style="display: none;">Sidebar</a>
                    <ul>
                        <li class="sub classSidebarLi" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[0]?.isRead">
                            <a class="sidebar-icon" [routerLink]="['/dashboard']" [routerLinkActive]="'nav-active'">
                                <i class="icon mr-2 dashBoard" [appSvgIcon]="'dashBoard'"></i>
                                Dashboard
                            </a>
                        </li>
                        <!-- <li class="sub classSidebarLi"><a
                            [routerLink]="['/dashboard-new']" [routerLinkActive]="'nav-active'">Dashboard New</a></li> -->
                        <li class='sub classSidebarLi' *ngIf="roleValueLogin[11]?.isRead||roleValueLogin[3]?.isRead"
                            [hidden]="this.showDetailList=='R'">
                            <a class="sidebar-icon d-flex justify-content-between"
                                [class.nav-activeDD]="router.url.includes('/employerSetup/')">
                                <span class="d-flex align-items-center">
                                    <i class="icon mr-2" [appSvgIcon]="'client'"></i>
                                    Employer
                                </span>
                                <i class="fa fa-angle-right mr-1 pos-n-font" aria-hidden="true"></i>
                            </a>
                            <ul>
                                <li class="sub" *ngIf="roleValueLogin[11]?.isRead" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                        [routerLink]="['/allClient/employer-setup']"
                                        [routerLinkActive]="'nav-active'">Employer List</a></li>
                                <li class="sub" *ngIf="roleValueLogin[3]?.isRead"><a
                                        [class.nav-active]="router.url.includes('/employerSetup/payroll/')">Payroll
                                        Setup</a>
                                    <ul>
                                        <li class="sub"><a class="sidebar-child-icon" (click)="toggleSideBarmobile()"
                                                [routerLink]="['/employerSetup/payroll/pay-schedule']"
                                                [routerLinkActive]="'nav-active'">Pay Schedules</a></li>
                                        <li class='sub'><a class="sidebar-child-icon" (click)="toggleSideBarmobile()"
                                                [routerLink]="['/employerSetup/payroll/time-off-policies']"
                                                [routerLinkActive]="'nav-active'">Time Off Policies</a> </li>
                                        <li class="sub"><a class="sidebar-child-icon" (click)="toggleSideBarmobile()"
                                                [routerLink]="['/employerSetup/payroll/deduction-contribution']"
                                                [routerLinkActive]="'nav-active'">Contribution Info</a></li>
                                                <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                    [routerLink]="['/allClient/prior/prior-taxes-paid']"
                                                    [routerLinkActive]="'nav-active'">Prior Taxes Paid</a>
                                            </li>
                                        <li class='sub'><a class="sidebar-child-icon" (click)="toggleSideBarmobile()"
                                                [routerLink]="['/employerSetup/payroll/direct-deposit']"
                                                [routerLinkActive]="'nav-active'">Direct Deposit</a>
                                              
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class='sub classSidebarLi' *ngIf="roleValueLogin[11]?.isRead||roleValueLogin[3]?.isRead"
                            [hidden]="this.showDetailList=='S'">
                            <a class="sidebar-icon d-flex justify-content-between"
                                [class.nav-activeDD]="router.url.includes('/employer-setup')">
                                <span class="d-flex align-items-center">
                                    <i class="icon mr-2" [appSvgIcon]="'client'"></i>
                                    Employer Details
                                </span>
                                <i class="fa fa-angle-right mr-1 pos-n-font" aria-hidden="true"></i>
                            </a>
                            <ul>
                                <li class="sub" *ngIf="roleValueLogin[11]?.isRead"><a class="sidebar-child-icon"
                                        [routerLink]="['/allClient/employer-setup']"
                                        [routerLinkActive]="'nav-active'">My Details</a></li>
                                <li class="sub" *ngIf="roleValueLogin[3]?.isRead"><a
                                        [class.nav-active]="router.url.includes('/employerSetup/payroll/')">Payroll
                                        Setup</a>
                                    <ul>
                                        <li class="sub"><a class="sidebar-child-icon"
                                                [routerLink]="['/employerSetup/payroll/pay-schedule']"
                                                [routerLinkActive]="'nav-active'">Pay Schedules</a></li>
                                        <li class="sidebar-child-icon" class='sub'><a
                                                [routerLink]="['/employerSetup/payroll/time-off-policies']"
                                                [routerLinkActive]="'nav-active'">Time Off Policies</a> </li>
                                        <li class="sidebar-child-icon" class="sub"><a
                                                [routerLink]="['/employerSetup/payroll/deduction-contribution']"
                                                [routerLinkActive]="'nav-active'">Contribution Info</a></li>
                                                <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                    [routerLink]="['/allClient/prior/prior-taxes-paid']"
                                                    [routerLinkActive]="'nav-active'">Prior Taxes Paid</a>
                                            </li>
                                        <li class="sidebar-child-icon" class='sub'><a
                                                [routerLink]="['/employerSetup/payroll/direct-deposit']"
                                                [routerLinkActive]="'nav-active'">Direct Deposit</a>
                                                
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class='sub classSidebarLi'
                            *ngIf="this.userType!='W2_EMPLOYEE' && this.userType!='CONTRACTOR'">
                            <a class="sidebar-icon d-flex justify-content-between"
                                [class.nav-activeDD]="router.url.includes('/allClient/teamMembers/')">
                                <span class="d-flex align-items-center">
                                    <i class="icon mr-2" [appSvgIcon]="'allTeam'"></i>
                                    Team Member
                                </span>
                                <i class="fa fa-angle-right mr-1 pos-n-font" aria-hidden="true"></i>
                            </a>
                            <!-- <a
                                [class.nav-activeDD]="router.url.includes('/allClient/teamMembers/')">Team Member</a> -->
                            <ul>
                                <li class="sub"><a
                                        [class.nav-active]="router.url.includes('/allClient/teamMembers/')">Team Member
                                        List</a>
                                    <ul>
                                        <li class="sub"><a class="sidebar-child-icon" (click)="toggleSideBarmobile()"
                                                [routerLink]="['/allClient/teamMembers/allteamMember']"
                                                [routerLinkActive]="'nav-active'">All Team Members</a></li>
                                        <li class='sub' (click)="toggleSideBarmobile()"
                                            *ngIf="roleValueLogin[6]?.isRead||roleValueLogin[7]?.isRead||roleValueLogin[8]?.isRead||roleValueLogin[9]?.isRead||roleValueLogin[10]?.isRead">
                                            <a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/teamMembers/employee/employeeBasics']"
                                                [routerLinkActive]="'nav-active'">Employees</a>
                                        </li>
                                        <li class='sub' *ngIf="roleValueLogin[4]?.isRead||roleValueLogin[5]?.isRead" (click)="toggleSideBarmobile()"><a
                                                class="sidebar-child-icon"
                                                [routerLink]="['/allClient/teamMembers/contractor/contractorBasic']"
                                                [routerLinkActive]="'nav-active'">Contractor</a>
                                        </li>
                                    </ul>
                                </li>
                                <!-- <li [hidden]="this.message=='F'" *ngIf="roleValueLogin[1]?.isRead" class="sub"><a
                                        [class.nav-active]="router.url.includes('/allClient/prior/')">Prior Payroll and
                                        Taxes</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/prior/priorPayroll']"
                                                [routerLinkActive]="'nav-active'">Prior Payroll</a></li> -->
                                        <!-- <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/prior/prior-taxes-paid']"
                                                [routerLinkActive]="'nav-active'">Prior Taxes Paid</a>
                                        </li> -->
                                    <!-- </ul>
                                </li> -->
                            </ul>
                        </li>
                        <li class='sub classSidebarLi' *ngIf="this.userType=='W2_EMPLOYEE'">
                            <a class="sidebar-icon d-flex justify-content-between"
                                [class.nav-activeDD]="router.url.includes('/allClient/teamMembers/')">
                                <span class="d-flex-align-items-center">
                                    <i class="icon mr-2" style="margin-right: 0.2rem !important"
                                        [appSvgIcon]="'allTeam'"></i>
                                    Team Member
                                </span>
                                <i class="fa fa-angle-right mr-1 pos-n-font" aria-hidden="true"></i>
                            </a>
                            <ul>
                                <li class="sub"><a
                                        [class.nav-active]="router.url.includes('/allClient/teamMembers/')">Team Member
                                        List</a>
                                    <ul>
                                        <li class="sub"><a class="sidebar-child-icon" (click)="toggleSideBarmobile()"
                                                [routerLink]="['/allClient/teamMembers/allteamMember']"
                                                [routerLinkActive]="'nav-active'">All Team Members</a></li>
                                        <li class='sub' (click)="toggleSideBarmobile()"
                                            *ngIf="roleValueLogin[6]?.isRead||roleValueLogin[7]?.isRead||roleValueLogin[8]?.isRead||roleValueLogin[9]?.isRead||roleValueLogin[10]?.isRead">
                                            <a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/teamMembers/employee/employeeBasics']"
                                                [routerLinkActive]="'nav-active'">Employees</a>
                                        </li>
                                        <li class='sub' *ngIf="roleValueLogin[4]?.isRead||roleValueLogin[5]?.isRead" (click)="toggleSideBarmobile()"><a
                                                class="sidebar-child-icon"
                                                [routerLink]="['/allClient/teamMembers/contractor/contractorBasic']"
                                                [routerLinkActive]="'nav-active'">Contractor</a>
                                        </li>
                                    </ul>
                                </li>
                                <li [hidden]="this.message=='F'" *ngIf="roleValueLogin[1]?.isRead" class="sub"><a
                                        [class.nav-active]="router.url.includes('/allClient/prior/')">Prior Payroll and
                                        Taxes</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/prior/priorPayroll']"
                                                [routerLinkActive]="'nav-active'">Prior Payroll</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/prior/prior-taxes-paid']"
                                                [routerLinkActive]="'nav-active'">Prior Taxes Paid</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class='sub classSidebarLi' *ngIf="this.userType=='CONTRACTOR'">
                            <a class="sidebar-icon d-flex justify-content-between"
                                [class.nav-activeDD]="router.url.includes('/allClient/teamMembers/')">
                                <span class="d-flex-align-items-center">
                                    <i class="icon mr-2" [appSvgIcon]="'allTeam'"></i>
                                    Team Member
                                </span>
                                <i class="fa fa-angle-right mr-1 pos-n-font" aria-hidden="true"></i>
                            </a>
                            <!-- <a
                                [class.nav-activeDD]="router.url.includes('/allClient/teamMembers/')">Team Member</a> -->
                            <ul>
                                <li class="sub"><a
                                        [class.nav-active]="router.url.includes('/allClient/teamMembers/')">Team Member
                                        List</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/teamMembers/allteamMember']"
                                                [routerLinkActive]="'nav-active'">All Team Members</a></li>
                                        <li class='sub' (click)="toggleSideBarmobile()"
                                            *ngIf="roleValueLogin[6]?.isRead||roleValueLogin[7]?.isRead||roleValueLogin[8]?.isRead||roleValueLogin[9]?.isRead||roleValueLogin[10]?.isRead">
                                            <a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/teamMembers/employee/employeeBasics']"
                                                [routerLinkActive]="'nav-active'">Employees</a>
                                        </li>
                                        <li class='sub' (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[4]?.isRead||roleValueLogin[5]?.isRead"><a
                                                class="sidebar-child-icon"
                                                [routerLink]="['/allClient/teamMembers/contractor/contractorBasic']"
                                                [routerLinkActive]="'nav-active'">Contractor</a>
                                        </li>
                                    </ul>
                                </li>
                                <li [hidden]="this.message=='F'" *ngIf="roleValueLogin[1]?.isRead" class="sub"><a
                                        [class.nav-active]="router.url.includes('/allClient/prior/')">Prior Payroll and
                                        Taxes</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/prior/priorPayroll']"
                                                [routerLinkActive]="'nav-active'">Prior Payroll</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/allClient/prior/prior-taxes-paid']"
                                                [routerLinkActive]="'nav-active'">Prior Taxes Paid</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="sub classSidebarLi" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[20]?.isRead">
                            <a class="sidebar-icon" [routerLink]="['/process/main-payroll/payroll']"
                                [routerLinkActive]="'nav-active'">
                                <i class="icon mr-2 dashBoard" [appSvgIcon]="'payroll'"></i>
                                Process Payroll
                            </a>
                        </li>
                        <li class='sub classSidebarLi' *ngIf="roleValueLogin[2]?.isRead">
                            <a class="sidebar-icon d-flex justify-content-between"
                                [class.nav-activeDD]="router.url.includes('/taxes/')">
                                <span class="d-flex align-items-center">
                                    <i class="icon mr-2" [appSvgIcon]="'taxes'"></i>
                                    Taxes
                                </span>
                                <i class="fa fa-angle-right mr-1 pos-n-font" aria-hidden="true"></i>
                            </a>
                            <ul>
                                <li class="sub" *ngIf="roleValueLogin[22]?.isRead"><a
                                        [class.nav-active]="router.url.includes('/taxes/liability/')">Pay Taxes</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/taxes/liability/pay-taxes']"
                                                [routerLinkActive]="'nav-active'">Process Tax Liability Payments</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="sub" *ngIf="roleValueLogin[23]?.isRead||roleValueLogin[24]?.isRead">
                                    <a [class.nav-active]="router.url.includes('/taxes/processTaxes/')">Process Tax
                                        Forms</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[23]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/taxes/processTaxes/process-tax-forms']"
                                                [routerLinkActive]="'nav-active'">Quarterly Forms</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[24]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/taxes/processTaxes/yearly-forms']"
                                                [routerLinkActive]="'nav-active'">Yearly Forms</a></li>
                                    </ul>
                                </li>
                                <li class="sub"
                                    *ngIf="roleValueLogin[25]?.isRead||roleValueLogin[26]?.isRead||roleValueLogin[27]?.isRead">
                                    <a [class.nav-active]="router.url.includes('/taxes/filling/')">Prior Tax
                                        Fillings</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[25]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/taxes/filling/prior-tax-filings']"
                                                [routerLinkActive]="'nav-active'">Archived Federal Forms</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[26]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/taxes/filling/archived-state-forms']"
                                                [routerLinkActive]="'nav-active'">Archived State Forms</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[27]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/taxes/filling/archived-yearly-forms']"
                                                [routerLinkActive]="'nav-active'">Archived Yearly Forms</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class='sub classSidebarLi'>
                            <a class="sidebar-icon d-flex justify-content-between"
                                [class.nav-activeDD]="router.url.includes('/report/')">
                                <span class="d-flex align-items-center">
                                    <i class="icon mr-2" [appSvgIcon]="'reports'"></i>
                                    Reports
                                </span>
                                <i class="fa fa-angle-right mr-1 pos-n-font" aria-hidden="true"></i>
                            </a>
                            <ul>
                                <li class="sub" *ngIf="roleValueLogin[19]?.isRead"><a
                                        [class.nav-active]="router.url.includes('/report/employeerReport/')">Employer
                                        Reports</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/employeerReport/report-tax-liability']"
                                                [routerLinkActive]="'nav-active'">Tax Liability</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()">
                                            <a class="sidebar-child-icon" [routerLinkActive]="'nav-active'"
                                                [routerLink]="['/report/employeerReport/tax-liability-details']">
                                                Tax Liability Details
                                            </a>
                                        </li>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/employeerReport/report-tax-payment']"
                                                [routerLinkActive]="'nav-active'">Tax Payments</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/employeerReport/tax-wage-summary']"
                                                [routerLinkActive]="'nav-active'">Tax and Wage Summary</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/employeerReport/total-cost-report']"
                                                [routerLinkActive]="'nav-active'">Total Cost Report</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/employeerReport/worker-compensation-class']"
                                                [routerLinkActive]="'nav-active'">Workers Compensation Class</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/employeerReport/vacation-sick-leave']"
                                                [routerLinkActive]="'nav-active'">Vacation and Sick Leave</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/employeerReport/retirement-plan']"
                                                [routerLinkActive]="'nav-active'">Retirement Plans</a></li>
                                    </ul>
                                </li>
                                <li class="sub"
                                    *ngIf="roleValueLogin[16]?.isRead||roleValueLogin[15]?.isRead||roleValueLogin[14]?.isRead||roleValueLogin[13]?.isRead">
                                    <a [class.nav-active]="router.url.includes('/report/reportEmployee/')">Employee
                                        Reports</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[16]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/reportEmployee/payroll-summary']"
                                                [routerLinkActive]="'nav-active'">Payroll Summary</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[15]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/reportEmployee/employee-payroll-detail']"
                                                [routerLinkActive]="'nav-active'">Payroll Details</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[14]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/reportEmployee/employee-pay-checklist']"
                                                [routerLinkActive]="'nav-active'">Payroll Check List</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[13]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/reportEmployee/employee-deduction-contribution']"
                                                [routerLinkActive]="'nav-active'">Deductions and Contributions</a></li>
                                    </ul>
                                </li>
                                <li class="sub" *ngIf="roleValueLogin[18]?.isRead||roleValueLogin[17]?.isRead">
                                    <a [class.nav-active]="router.url.includes('/report/payContractor/')">Contractor
                                        Reports</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[18]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/payContractor/contractor-pay-summary']"
                                                [routerLinkActive]="'nav-active'">Contractor Pay Summary</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[17]?.isRead"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/payContractor/contractor-payment-list']"
                                                [routerLinkActive]="'nav-active'">Contractor Payments List</a></li>
                                    </ul>
                                </li>
                                <li class="sub" *ngIf="roleValueLogin[12]?.isRead"><a
                                        [class.nav-active]="router.url.includes('/report/admin-report/')">Admin
                                        Reports</a>
                                    <ul>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/admin-report/team-member-directory']"
                                                [routerLinkActive]="'nav-active'">Team Member Directory</a></li>
                                        <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon"
                                                [routerLink]="['/report/admin-report/admin-billing']"
                                                [routerLinkActive]="'nav-active'">Billing</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class='sub classSidebarLi'
                            *ngIf="roleValueLogin[21]?.isRead||roleValueLogin[28]?.isRead||roleValueLogin[29]?.isRead||roleValueLogin[30]?.isRead">
                            <a class="sidebar-icon d-flex justify-content-between"
                                [class.nav-activeDD]="router.url.includes('/userManagement/')">
                                <span class="d-flex align-items-center">
                                    <i class="icon mr-2" [appSvgIcon]="'userManagement'"></i>
                                    User Management
                                </span>
                                <i class="fa fa-angle-right mr-1 pos-n-font" aria-hidden="true"></i>
                            </a>
                            <ul>
                                <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[28]?.isRead"><a class="sidebar-child-icon"
                                        [routerLink]="['/userManagement/allUser']"
                                        [routerLinkActive]="'nav-active'">Users</a></li>
                                <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[29]?.isRead"><a class="sidebar-child-icon"
                                        [routerLink]="['/userManagement/userRoles']"
                                        [routerLinkActive]="'nav-active'">User Roles</a></li>
                                <li class="sub" (click)="toggleSideBarmobile()" *ngIf="roleValueLogin[30]?.isRead"><a class="sidebar-child-icon"
                                        [routerLink]="['/userManagement/employee-role']"
                                        [routerLinkActive]="'nav-active'">Employer Policy</a></li>
                            </ul>
                        </li>
                        <!-- <li class='sub classSidebarLi'>
                            <a [routerLink]="['/buy-subscription']" [routerLinkActive]="'nav-activeDD'"
                            style="white-space: nowrap;">Buy Subscription</a>
                        </li> -->
                        <li class='sub classSidebarLi'>
                            <a class="sidebar-icon d-flex justify-content-between"
                                [routerLink]="['/authenticatTwoVerified']" [routerLinkActive]="'nav-activeDD'">
                                <span class="d-flex align-items-center">
                                    <i [appSvgIcon]="'setting'" class="icon mr-2 setting-icon"></i>
                                    Settings
                                </span>
                                <i class="fa fa-angle-right mr-1 pos-n-font" aria-hidden="true"></i>
                            </a>
                            <ul>
                                <li class="sub" (click)="toggleSideBarmobile()"><a class="sidebar-child-icon" [routerLink]="['/authenticatTwoVerified']"
                                        [routerLinkActive]="'nav-active'">Two Factor Authentication</a></li>
                                <!-- <li class='sub classSidebarLi'>
                                            <a class="sidebar-child-icon" [class.nav-active]="router.url.includes('/subscription/')">
                                                <span class="d-flex align-items-center">
                                                    Subscription
                                                </span>
                                            </a>
                                            <ul>
                                                <li class="sub" *ngIf="roleValueLogin[28]?.isRead"><a class="sidebar-child-icon"
                                                        [routerLink]="['/subscription/create-plan']"
                                                        [routerLinkActive]="'nav-active'">Create Plan</a>
                                                </li>
                                                <li class="sub" *ngIf="roleValueLogin[28]?.isRead"><a class="sidebar-child-icon"
                                                    [routerLink]="['/subscription/all-plans']"
                                                    [routerLinkActive]="'nav-active'">All Plans</a>
                                                </li>
                                                <li class="sub" *ngIf="roleValueLogin[28]?.isRead"><a class="sidebar-child-icon"
                                                    [routerLink]="['/subscription/new-subscription']"
                                                    [routerLinkActive]="'nav-active'">Assign Plans</a>
                                                </li>
                                                <li class="sub" *ngIf="roleValueLogin[28]?.isRead"><a class="sidebar-child-icon"
                                                    [routerLink]="['/subscription/all-tenents']"
                                                    [routerLinkActive]="'nav-active'">All Client Plans</a>
                                                </li>
                                            </ul>
                                        </li> -->
                            </ul>
                        </li>
                        <!-- <li class="sub classSidebarLi">
                            <a [routerLink]="['/subscription-management']" [routerLinkActive]="'nav-activeDD'"
                            style="white-space: nowrap;">Subscription Management</a>
                        </li> -->
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div class="mobile-view-nav" [ngClass]="{'show-nav': showMobileViewSideBar}" #mobileView>
        <div class="nav-container" tabindex="0">
            <div class="nav-toggle">
            </div>
            <div class="nav-items nav-icons">
                <ul style="list-style: none; padding: 0;">
                    <li class="sub sub2 nav-item"><a style="display: none;">Sidebar</a>
                        <ul class="display-flex-icon" style="list-style: none; padding: 0;">
                            <li class="sub classSidebarLi" *ngIf="roleValueLogin[0]?.isRead">
                                <a class="sidebar-icon" [routerLinkActive]="'nav-active'">
                                    <i pTooltip="Dashboard" tooltipStyleClass="sidebar-tooltip"
                                        class="icon mr-2 dashBoard" [appSvgIcon]="'dashBoard'"></i>
                                </a>
                            </li>
                            <li class="sub classSidebarLi" *ngIf="roleValueLogin[11]?.isRead||roleValueLogin[3]?.isRead"
                                [hidden]="this.showDetailList=='R'">
                                <a class="sidebar-icon" [class.nav-activeDD]="router.url.includes('/employerSetup/')">
                                    <i tooltipStyleClass="sidebar-tooltip" pTooltip="Employer" class="icon mr-2"
                                        [appSvgIcon]="'client'"></i>
                                </a>
                            </li>
                            <li class="sub classSidebarLi"
                                *ngIf="this.userType!='W2_EMPLOYEE' && this.userType!='CONTRACTOR'">
                                <a class="sidebar-icon"
                                    [class.nav-activeDD]="router.url.includes('/allClient/teamMembers/')">
                                    <i tooltipStyleClass="sidebar-tooltip" pTooltip="Team Member" class="icon mr-2"
                                        [appSvgIcon]="'allTeam'"></i>
                                </a>
                            </li>
                            <li class="sub classSidebarLi" *ngIf="roleValueLogin[20]?.isRead">
                                <a class="sidebar-icon" [routerLinkActive]="'nav-active'">
                                    <i tooltipStyleClass="sidebar-tooltip" pTooltip="Process Payroll"
                                        class="icon mr-2 dashBoard" [appSvgIcon]="'payroll'"></i>
                                </a>
                            </li>
                            <li class="sub classSidebarLi" *ngIf="roleValueLogin[2]?.isRead">
                                <a class="sidebar-icon" [class.nav-activeDD]="router.url.includes('/taxes/')">
                                    <i tooltipStyleClass="sidebar-tooltip" pTooltip="Taxes" class="icon mr-2"
                                        [appSvgIcon]="'taxes'"></i>
                                </a>
                            </li>
                            <li class="sub classSidebarLi">
                                <a class="sidebar-icon" [class.nav-activeDD]="router.url.includes('/report/')">
                                    <i tooltipStyleClass="sidebar-tooltip" pTooltip="Report" class="icon mr-2"
                                        [appSvgIcon]="'reports'"></i>
                                </a>
                            </li>
                            <li class="sub classSidebarLi"
                                *ngIf="roleValueLogin[21]?.isRead||roleValueLogin[28]?.isRead||roleValueLogin[29]?.isRead||roleValueLogin[30]?.isRead">
                                <a class="sidebar-icon" [class.nav-activeDD]="router.url.includes('/userManagement/')">
                                    <i tooltipStyleClass="sidebar-tooltip" pTooltip="User Management" class="icon mr-2"
                                        [appSvgIcon]="'userManagement'"></i>
                                </a>
                            </li>
                            <li class="sub classSidebarLi">
                                <a class="sidebar-icon" [routerLinkActive]="'nav-activeDD'">
                                    <i tooltipStyleClass="sidebar-tooltip" pTooltip="Settings" [appSvgIcon]="'setting'"
                                        class="icon mr-2 setting-icon-mob"></i>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>