import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(readonly http: HttpClient) { }

  clientDetail = new BehaviorSubject<string>('Client Detail');
  telecastClientDetail = this.clientDetail.asObservable();

}
