<div class="footerCss"><span style="cursor: pointer;" (click)="display = true">Terms and Conditions</span> ●
    V{{version}} ● Copyright
    2009-{{currentYear}} {{projectName}}. All
    rights
    reserved.
</div>

<p-dialog class="ach-cal" [dismissableMask]="true" [modal]="true" header="Terms and Conditions" [(visible)]="display"
    [style]="{width: '43vw','margin-top':'10px'}">
    <div class="text-center">
        <h2>TERMS OF USE</h2>
    </div>
    <div class="text">
        <p>These Terms of Use (“Terms of Use”), which include and hereby incorporate the Privacy Policy at [
            https://slickpayroll.com/privacy-policy/] (“Privacy Policy”), is a legal agreement between Slick Payroll
            and you ("You " or “User”).</p>
    </div>
</p-dialog>