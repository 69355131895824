import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { AppInterceptor } from './service/app.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CustomerService } from '../app/user-management/customerService';
import { MainContainerComponent } from './main-container/main-container.component';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastrModule } from 'ngx-toastr';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication/two-factor-authentication.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ResetComponent } from './user/reset/reset.component';
import { EmailVerifiedSuccessfullyComponent } from './email-verified-successfully/email-verified-successfully.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DatePipe } from '@angular/common';
// import { ChartsModule } from 'ng2-charts';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './service/loader.service';
import { LoaderComponent } from './loader/loader.component';
import { RandomServiceService } from './service/random-service.service';
import { SideShowHideService } from './service/side-show-hide.service';
// import { NgxCalendarModule } from 'ss-ngx-calendar';
import { EmployeeNameService } from './service/employee-name.service';
import { ContractorNameService } from './service/contractor-name.service';
import { TwoDigitDecimaNumberDirectiveDirective } from './service/two-digit-decima-number-directive.directive';
import { EmployeeService } from './service/employee.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './shared.module';
import { AutoLogOffService } from './service/auto-logoff.service';
import { TrackCapsDirective } from './login/caps-lock.directive';
import { InactiveComponent } from './inactive/inactive.component';
import { FooterComponent } from './footer/footer.component';
// import { NgxCaptchaModule } from 'ngx-captcha';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    SidebarComponent,
    HeaderComponent,
    MainContainerComponent,
    TwoFactorAuthenticationComponent,
    VerifyEmailComponent,
    ResetComponent,
    EmailVerifiedSuccessfullyComponent,
    LoaderComponent,
    TrackCapsDirective,
    InactiveComponent,
    FooterComponent
  ],
  imports: [
    // NgxCaptchaModule,
    SharedModule,
    TooltipModule,
    RadioButtonModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    // NgxCalendarModule,
    // ChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    QRCodeModule,
    ReactiveFormsModule,
    InputTextModule,
    CalendarModule,
    ButtonModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      /*  preventDuplicates: true, */
    }),
    TableModule,
    ToastModule,
    CheckboxModule,
    TabViewModule,
    ScrollingModule,
    RippleModule,
    DropdownModule,
    DialogModule,
    FormsModule,
    AppRoutingModule,
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true
    }, EmployeeService,
    CustomerService, DatePipe, LoaderService, ContractorNameService, RandomServiceService, SideShowHideService, EmployeeNameService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
