import { Component, OnInit } from '@angular/core';
import { UserService } from '../user-management/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  version: string;
  currentYear = new Date().getFullYear();
  projectName = 'Slick Payroll';
  display = false;

  constructor(
    readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.getVersion()
  }

  getVersion() {
    this.userService.getFooterData().subscribe(resp => {
      this.version = resp.data;
    })
  }

}
