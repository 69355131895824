import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserService } from '../user-management/user.service';
import { ToastrService } from 'ngx-toastr';
import { RandomServiceService } from '../service/random-service.service';
@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.css']
})
export class TwoFactorAuthenticationComponent implements OnInit {
  token: string;
  verifyForm: UntypedFormGroup;
  phoneNumberForm: UntypedFormGroup;
  googleAuth = true;
  phoneUse: boolean;
  public qrCodeImage: string = null;
  id: boolean;
  message: string;

  constructor(
    // tslint:disable-next-line: variable-name
    readonly _FormBuilder: UntypedFormBuilder,
    readonly someserv: RandomServiceService,
    readonly userService: UserService,
    readonly router: Router,
    readonly toast: ToastrService) {
    this.verifyForm = this._FormBuilder.group({
      verifyOtpInput: ['']
    });
    this.phoneNumberForm = this._FormBuilder.group({
      verifyNumber: [''],
      verifyCode: [''],
    });
  }

  ngOnInit(): void {
    this.message = localStorage.getItem('businessName');
    if (this.message) {
      this.someserv.editMsg(this.message);
    }
    this.checkToken();
    this.getQrCode();
  }

  checkToken(): void {
    this.token = localStorage.getItem('token');
  }

  useGoogleAuthenyication(): void {
    this.googleAuth = true;
    this.phoneUse = false;
  }

  usePhones(): void {
    this.phoneUse = true;
    this.googleAuth = false;
  }

  onSubmit(): void {
    const formData = {
      otp: this.verifyForm.value.verifyOtpInput
    };
    this.userService.twoFactorVerify(formData.otp).subscribe((data: any) => {
      if (data && data.status === 200) {
        this.toast.success(data.message);
        this.router.navigate(['/userManagement/allUser']);
      }
      else {
        this.toast.warning(data.message);
      }
    }, (err) => {
      this.toast.warning(err.error.message);
    });
  }

  getQrCode(): void {
    this.id = true;
    this.userService.google2FaImage(this.id).subscribe((data: any) => {
      if (data && data.status === 200) {
        this.qrCodeImage = data.data.base64Image;
        this.toast.success(data.message);
      }
      else {
        this.toast.warning(data.message);
      }
    }, (err) => {
      this.toast.warning(err.error.message);
    });
  }

  skip(): void {
    this.router.navigate(['/userManagement/allUser']);
  }

  onSubmitPhone(): void { }
}
