import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
// import { ClientComponent } from './client/client.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
// import { UserManagementComponent } from './user-management/user-management.component';
// import { UserRolesComponent } from './user-management/user-roles/user-roles.component';
// import { ProcessPayrollComponent } from './process-payroll/process-payroll.component';
// import { AllTeamComponent } from './client/all-team/all-team.component';
// import { EmployeeComponent } from './client/employee/employee.component';
// import { EmployerSetupComponent } from './client/employer-setup/employer-setup.component';
// import { ContractorComponent } from './client/contractor/contractor.component';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication/two-factor-authentication.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
// import { PriorPayrollAndTaxesComponent } from './client/prior-payroll-and-taxes/prior-payroll-and-taxes.component';
// import { PriorTaxesPaidComponent } from './client/prior-payroll-and-taxes/prior-taxes-paid/prior-taxes-paid.component';
// import { TaxesComponent } from './taxes/taxes.component';
// import { ProcessTaxFormsComponent } from './taxes/process-tax-forms/process-tax-forms.component';
// import { YearlyFormsComponent } from './taxes/process-tax-forms/yearly-forms/yearly-forms.component';
// import { PriorTaxFilingsComponent } from './taxes/prior-tax-filings/prior-tax-filings.component';
// import { FormVaultComponent } from './taxes/form-vault/form-vault.component';
// import { ArchivedStateFormsComponent } from './taxes/prior-tax-filings/archived-state-forms/archived-state-forms.component';
// import { ArchivedYearlyFormsComponent } from './taxes/prior-tax-filings/archived-yearly-forms/archived-yearly-forms.component';
import { AuthenticationGuard } from '../app/service/authentication.guard';
import { ResetComponent } from './user/reset/reset.component';
import { EmailVerifiedSuccessfullyComponent } from './email-verified-successfully/email-verified-successfully.component';
import { AuthenticationTwoVerificationComponent } from './authentication-two-verification/authentication-two-verification.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { MainProcessComponent } from './main-process/main-process.component';
// import { CreateTaxPaymentComponent } from './taxes/create-tax-payment/create-tax-payment.component';
// import { PrintTaxPaymentComponent } from './taxes/print-tax-payment/print-tax-payment.component';
// import { ReportTaxLiabilityComponent } from './report/report-tax-liability/report-tax-liability.component';
// import { ReportTaxPaymentComponent } from './report/report-tax-payment/report-tax-payment.component';
// import { TaxWageSummaryComponent } from './report/tax-wage-summary/tax-wage-summary.component';
// import { TotalCostReportComponent } from './report/total-cost-report/total-cost-report.component';
// import { WorkerCompensationClassComponent } from './report/worker-compensation-class/worker-compensation-class.component';
// import { VacationSickLeaveComponent } from './report/vacation-sick-leave/vacation-sick-leave.component';
// import { RetirementPlanComponent } from './report/retirement-plan/retirement-plan.component';
// import { AdminReportComponent } from './report/admin-report/admin-report.component';
// import { ContractorPaymentListComponent } from './report/contractor-payment-list/contractor-payment-list.component';
// import { ContractorPaySummaryComponent } from './report/contractor-pay-summary/contractor-pay-summary.component';
// import { PayrollSummaryComponent } from './report/payroll-summary/payroll-summary.component';
// import { EmployeePayChecklistComponent } from './report/employee-pay-checklist/employee-pay-checklist.component';
// import { EmployeeDeductionContributionComponent } from './report/employee-deduction-contribution/employee-deduction-contribution.component';
// import { EmployeePayrollDetailComponent } from './report/employee-payroll-detail/employee-payroll-detail.component';
// import { EmployeeAdminDetailComponent } from './report/employee-admin-detail/employee-admin-detail.component';
// import { AdminContractorDetailComponent } from './report/admin-contractor-detail/admin-contractor-detail.component';
// import { AdminBillingComponent } from './report/admin-billing/admin-billing.component';
// import { PayScheduleComponent } from './payroll/pay-schedule/pay-schedule.component';
// import { TimeOffPoliciesComponent } from './payroll/time-off-policies/time-off-policies.component';
// import { DeductionContributionComponent } from './payroll/deduction-contribution/deduction-contribution.component';
// import { DirectDepositComponent } from './payroll/direct-deposit/direct-deposit.component';
// import { CreatePayScheduleComponent } from './payroll/create-pay-schedule/create-pay-schedule.component';
// import { CreateTimePoliciesComponent } from './payroll/create-time-policies/create-time-policies.component';
// import { CreateDeductionContributionComponent } from './payroll/create-deduction-contribution/create-deduction-contribution.component';
// import { CreateGarnishmentsComponent } from './payroll/create-garnishments/create-garnishments.component';
// import { EmployerBankAccountComponent } from './payroll/employer-bank-account/employer-bank-account.component';
// import { ConfirmBankAccountComponent } from './payroll/confirm-bank-account/confirm-bank-account.component';
// import { ConfirmAccountComponent } from './payroll/confirm-account/confirm-account.component';
// import { PayScheduleMonthComponent } from './payroll/pay-schedule-month/pay-schedule-month.component';
// import { TwiceMonthPayScheduleComponent } from './payroll/twice-month-pay-schedule/twice-month-pay-schedule.component';
// import { ViewEmployeeDetailComponent } from './client/view-employee-detail/view-employee-detail.component';
// import { ViewContractorDetailComponent } from './client/view-contractor-detail/view-contractor-detail.component';
// import { EditProcessPayrollComponent } from './edit-process-payroll/edit-process-payroll.component';
// import { SecondProcessPayrollComponent } from './second-process-payroll/second-process-payroll.component';
// import { SecondProcessViewComponent } from './second-process-view/second-process-view.component';
// import { WagesDetailComponent } from './wages-detail/wages-detail.component';
// import { ViewContractorPaystubComponent } from './view-contractor-paystub/view-contractor-paystub.component';
// import { DetailViewChecklistSummaryComponent } from './report/detail-view-checklist-summary/detail-view-checklist-summary.component';
// import { EmployeeAdminRoleComponent } from './employee-admin-role/employee-admin-role.component';
// import { DashBoardAuthenticationGuard } from './service/dashboard-auth.guard';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';
import { SubscriptionManagementComponent } from './subscription-management/subscription-management.component';
import { CreateSubscriptionComponent } from './subscription-management/create-subscription/create-subscription.component';
import { BuySubscriptionComponent } from './subscription-management/buy-subscription/buy-subscription.component';
import { NewSubscriptionComponent } from './subscription-management/new-subscription/new-subscription.component';
import { CreatePlanComponent } from './subscription-management/create-plan/create-plan.component';
import { SubscriptionAddressComponent } from './subscription-management/subscription-address/subscription-address.component';
import { TenentListsComponent } from './subscription-management/tenent-lists/tenent-lists.component';
import { InactiveComponent } from './inactive/inactive.component';
// import { LiabilityTaxesComponent } from './report/liability-taxes/liability-taxes.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  // { path: 'session-expired', component: InactiveComponent },
  // { path: 'authenticatTwoVerified', component: AuthenticationTwoVerificationComponent },
  // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard] },
  // { path: 'dashboard', component: DashboardNewComponent, canActivate: [AuthenticationGuard] }
  // {
  //   path: 'subscription-management', component: SubscriptionManagementComponent
  // },
  // {
  //   path: 'create-new-subscription', component: CreateSubscriptionComponent
  // },
  // {
  //   path: 'buy-subscription', component: BuySubscriptionComponent
  // },
  // {path: 'new-subscription', component: NewSubscriptionComponent, pathMatch: 'full'},
  // { path: 'create-plan', component: CreatePlanComponent, pathMatch: 'full'},
  // { path: 'subscription-address', component: SubscriptionAddressComponent },
  // { path: 'all-tenents', pathMatch: 'full', component: TenentListsComponent },
  { path: 'emalVerified', component: EmailVerifiedSuccessfullyComponent },
  { path: 'twoFactorAuthentication', component: TwoFactorAuthenticationComponent, canActivate: [AuthenticationGuard] },
  { path: 'verifyEmail', component: VerifyEmailComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  { path: 'user/reset', component: ResetComponent },
  // { path: 'userManagement/employee-role', component: EmployeeAdminRoleComponent, canActivate: [AuthenticationGuard] },
  // { path: 'userManagement/allUser', component: UserManagementComponent, canActivate: [AuthenticationGuard] },
  // { path: 'userManagement/userRoles', component: UserRolesComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/allClient/employerSetup', component: ClientComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/allClient/employerSetup?id=', component: ClientComponent, canActivate: [AuthenticationGuard] },
  // { path: 'allClient/allTeam', component: AllTeamComponent, canActivate: [AuthenticationGuard] },
  // { path: 'allClient/prior/prior-taxes-paid', component: PriorPayrollAndTaxesComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/allClient/employer-setup', component: EmployerSetupComponent, canActivate: [AuthenticationGuard] },
  // { path: 'process/main-payroll/payroll', component: ProcessPayrollComponent, canActivate: [AuthenticationGuard] },
  // { path: 'taxes/liability/pay-taxes', component: TaxesComponent, canActivate: [AuthenticationGuard] },
  // { path: 'taxes/liability/create-tax-payment', component: CreateTaxPaymentComponent, canActivate: [AuthenticationGuard] },
  // { path: 'taxes/liability/print-tax-payment', component: PrintTaxPaymentComponent, canActivate: [AuthenticationGuard] },
  // { path: 'taxes/processTaxes/process-tax-forms', component: ProcessTaxFormsComponent, canActivate: [AuthenticationGuard] },
  // { path: 'taxes/processTaxes/yearly-forms', component: YearlyFormsComponent, canActivate: [AuthenticationGuard] },
  // { path: 'taxes/filling/prior-tax-filings', component: PriorTaxFilingsComponent, canActivate: [AuthenticationGuard] },
  // { path: 'taxes/form-vault', component: FormVaultComponent, canActivate: [AuthenticationGuard] },
  // { path: 'taxes/filling/archived-state-forms', component: ArchivedStateFormsComponent, canActivate: [AuthenticationGuard] },
  // { path: 'taxes/filling/archived-yearly-forms', component: ArchivedYearlyFormsComponent, canActivate: [AuthenticationGuard] }
  // { path: 'allClient/teamMembers/allteamMember', component: AllTeamComponent, canActivate: [AuthenticationGuard] },
  // { path: 'allClient/prior/priorPayroll', component: PriorTaxesPaidComponent, canActivate: [AuthenticationGuard] },
  // { path: 'allClient/teamMembers/contractor/contractorBasic', component: ContractorComponent, canActivate: [AuthenticationGuard] },
  // { path: 'allClient/teamMembers/contractor/viewContractorDetail',
  // component: ViewContractorDetailComponent, canActivate: [AuthenticationGuard] },
  // { path: 'allClient/teamMembers/employee/employeeBasics', component: EmployeeComponent, canActivate: [AuthenticationGuard] },
  // { path: 'allClient/teamMembers/employee/viewEmployeeDetail', component: ViewEmployeeDetailComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/employeerReport/report-tax-liability', component: ReportTaxLiabilityComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/employeerReport/report-tax-liability-details', component: LiabilityTaxesComponent, canActivate: [AuthenticationGuard]},
  // { path: 'report/employeerReport/report-tax-payment', component: ReportTaxPaymentComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/employeerReport/tax-wage-summary', component: TaxWageSummaryComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/employeerReport/total-cost-report', component: TotalCostReportComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/employeerReport/worker-compensation-class',
  // component: WorkerCompensationClassComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/employeerReport/vacation-sick-leave', component: VacationSickLeaveComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/employeerReport/retirement-plan', component: RetirementPlanComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/admin-report/team-member-directory', component: AdminReportComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/payContractor/contractor-payment-list', component: ContractorPaymentListComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/payContractor/contractor-pay-summary', component: ContractorPaySummaryComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/reportEmployee/payroll-summary', component: PayrollSummaryComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/reportEmployee/employee-pay-checklist', component: EmployeePayChecklistComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/reportEmployee/employee-deduction-contribution',
  // component: EmployeeDeductionContributionComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/reportEmployee/employee-payroll-detail', component: EmployeePayrollDetailComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/admin-report/employee-admin-detail', component: EmployeeAdminDetailComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/admin-report/admin-contractor-detail', component: AdminContractorDetailComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/admin-report/admin-billing', component: AdminBillingComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/pay-schedule', component: PayScheduleComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/time-off-policies', component: TimeOffPoliciesComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/deduction-contribution', component: DeductionContributionComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/direct-deposit', component: DirectDepositComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/create-pay-schedule', component: CreatePayScheduleComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/create-time-off-policies', component: CreateTimePoliciesComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/create-deduction-contribution',
  // component: CreateDeductionContributionComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/create-garnishments', component: CreateGarnishmentsComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/employer-bank-account', component: EmployerBankAccountComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/confirm-bank-account', component: ConfirmBankAccountComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/confirm-account', component: ConfirmAccountComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/payschedulemonth', component: PayScheduleMonthComponent, canActivate: [AuthenticationGuard] },
  // { path: 'employerSetup/payroll/twice-month-pay-schedule', component: TwiceMonthPayScheduleComponent, canActivate: [AuthenticationGuard] },
  // { path: 'process/main-payroll/edit-process-payroll', component: EditProcessPayrollComponent, canActivate: [AuthenticationGuard] },
  // { path: 'process/main-payroll/second-process-payroll', component: SecondProcessPayrollComponent, canActivate: [AuthenticationGuard] },
  // { path: 'process/main-payroll/second-process-view', component: SecondProcessViewComponent, canActivate: [AuthenticationGuard] },
  // { path: 'process/main-payroll/view-contractor-paystub', component: ViewContractorPaystubComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/employeerReport/tax-wage-detail', component: WagesDetailComponent, canActivate: [AuthenticationGuard] },
  // { path: 'report/employeerReport/detail-view-checklist-summary',
  // component: DetailViewChecklistSummaryComponent, canActivate: [AuthenticationGuard] },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: () => import('./dashboard-new/dashboard.module').then(module => module.DashboardModule) },
  { path: 'allClient', loadChildren: () => import('./client/client.module').then(module => module.ClientModule) },
  { path: 'report', loadChildren: () => import('./report/report.module').then(module => module.ReportModule) },
  { path: 'employerSetup', loadChildren: () => import('./payroll/payroll.module').then(module => module.PayrollModule) },
  { path: 'taxes', loadChildren: () => import('./taxes/taxes.module').then(module => module.TaxesModule) },
  { path: 'userManagement', loadChildren: () => import('./user-management/user-management.module').then(module => module.UserManagementModule) },
  { path: 'process', loadChildren: () => import('./process-payroll/process-payroll.module').then(module => module.ProcessPayrollModule) },
  { path: 'authenticatTwoVerified', loadChildren: () => import('./authentication-two-verification/auth-two.module').then(module => module.AuthTwoModule) },
  { path: 'subscription', loadChildren: () => import('./subscription-management/subscription-management.module').then(module => module.SubscriptionManagementModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

