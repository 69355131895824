<div class="p-5 pl-0" ml style="height: 660px;width: 100%;background: #fff;">
    <div class="row">
        <div class="col-6 h-95vh d-flex align-items-center justify-content-center bot-32 bgcolor">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <img width="60%" class="imgClass center mt-140" src="../../assets/images/login_image.svg" alt="">
            </div>
        </div>
        <div class="col-6">
            <div class="margin-center">
                <img style="height: 50px; width: 160px;" src="assets/images/logo.png" alt="">
                <h2 class="mb-3">Session Expired Please login again.</h2>
                <p class="mt-5">You will be automatically redirected to login page within a few second</p>
                <p>If you are not redirected click <u (click)="logout()" style="color: #0000ff;">here</u> to redirect
                </p>
            </div>
        </div>
    </div>
</div>