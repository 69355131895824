import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user-management/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard  {
  constructor(readonly Authguardservice: UserService, readonly router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.Authguardservice.gettoken()) {
      this.router.navigateByUrl('/login');
    }
    return this.Authguardservice.gettoken();
  }

  gettoken(): boolean {
    return !!localStorage.getItem('token');
  }
}
