<div class="h100 wd" *ngIf="!resetPasswordPage">
    <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-6 pr-5 center bgColor h100">
            <img width="80%" class="imgClass center mt-140" src="../../assets/images/login_image.svg" alt="">
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6 inherit" style="background-color: white;">
            <div class="row mt23 top-50">
                <div class="col-md-12 my-auto">
                    <div>
                        <img width="63%" style="margin-left: 18%;" src="../../assets/images/logo.png" alt="">
                    </div>
                </div>
                <div class="col-12">
                    <div class="col-md-12 dis-flex d-flex justify-content-center">
                        <h3 class="loginData">Login</h3>
                    </div>
                </div>
                <br>
                <div class="col-md-12">
                  
                    <form *ngIf="loginReactiveService.login_form" class="tCenter ml31"
                        [formGroup]="loginReactiveService.login_form" #form="ngForm">
                        <label for="UserName" class="classFontSize">Email Address</label>
                        <div class="p-inputgroup input-group form-group">
                            <span class="p-inputgroup-addon bg-white">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                            </span>
                            <input type="text" formControlName="emailId" pInputText class="form-control"
                                name="ema00ilId" [(ngModel)]="loginUser.emailId">
                        </div>
                        <br>
                        <label for="password" class="classFontSize">Password</label>
                        <div class="p-inputgroup input-group form-group">
                            <span class="p-inputgroup-addon bg-white">
                                <i class="fa fa-lock" aria-hidden="true"></i>
                            </span>
                            <input type="{{!showText ? 'password' : 'text'}}" class="form-control" pInputText
                                formControlName="password" name="password" [(ngModel)]="loginUser.password">
                            <i (click)="showText = !showText" class="show-hide-icon"
                                [ngClass]="showText ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"></i>
                        </div>
                        <p class="mt-1" *ngIf="showCapsLockText">Caps lock is on</p>
                        <div class="row align-items-center remember ml-2">
                            <span style="cursor: pointer;">
                                <label style="cursor: pointer;" class="mt-2 ml-2">&nbsp;<span
                                        class="classFontSize">Remember Me</span>
                                    <input type="checkbox" style="margin-left: -2px; cursor: pointer;">
                                    <span class="checkmark"></span>
                                </label>
                            </span>
                        </div>
                        <div class="captcha">
                            <form *ngIf="displayCaptcha"  [formGroup]="aFormGroup">
                                <ngx-recaptcha2 #captchaElem 
                                 [siteKey]="siteKey"
                                 (success)="handleSuccess($event)"
                                 [size]="size"
                                 [theme]="theme"
                                 [type]="type"
                                 [useGlobalDomain]="false" 
                                 formControlName="recaptcha">
                                </ngx-recaptcha2>
                            </form>
                        </div>
                        <div class="col-md-9 resets w-215" style="text-align: end;">
                            <a class="dropdown-item" (click)="resetPassword()" class="classFontSize"
                                style="cursor: pointer;">
                                Reset Password?
                            </a>
                        </div>
                        <div class="form-group mt-2">
                            <button type="submit"
                                [disabled]="loginReactiveService.login_form.controls['password'].invalid && loginReactiveService.login_form.controls['emailId'].invalid"
                                (click)="loginuser()" value="Login" class="submitButton btn btn-md">Login</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer footerStyle">
    <span style="cursor: pointer;">Terms and Conditions&nbsp;</span> ●
    V{{version}} ● Copyright
    2009-{{currentYear}} {{projectName}}. All
    rights
    reserved.
</div>