import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NavigationStart, Router, RouterEvent ,Event } from '@angular/router';
import { AppService } from './service/app.service';
import { AutoLogOffService } from './service/auto-logoff.service';
import {  } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'Slick Payroll';
  public token: string;
  href: any = '';
  public: boolean;
  publicReset: boolean;
  publicVerified: boolean;
  publicConformPassword: boolean;
  mobileView = false;
  webView = true;
  isInactive = false

  constructor(
    public messageService: MessageService,
    readonly router: Router,
    readonly appService: AppService,
    readonly autoLogOffService: AutoLogOffService
  ) {
    this.router.events.subscribe((event:Event| RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.token = localStorage.getItem('token');
        if (event['url'].includes('/emalVerified')) {
          this.publicVerified = true;
          this.public = false;
          this.publicReset = false;
          this.publicConformPassword = false;
        }
        else if (event['url'].includes('/resetPassword')) {
          this.publicReset = true;
          this.public = false;
          this.publicVerified = false;
          this.publicConformPassword = false;
        }
        else if (event['url'].includes('/user/reset')) {
          this.publicConformPassword = true;
          this.publicReset = false;
          this.publicVerified = false;
          this.public = false;
        }
        else if (event['url'].includes('/login') || event['url'].includes('/')) {
          this.public = true;
          this.publicReset = false;
          this.publicVerified = false;
          this.publicConformPassword = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.appService.toggleSideBarView.subscribe((value: boolean) => {
      this.mobileView = value;
    })
    if (window.innerWidth < 1200) {
      this.webView = false;
      this.mobileView = true;
    } else {
      this.webView = true;
      this.mobileView = false;
    }
    this.token = localStorage.getItem('token');
    setTimeout(() => {
      if (this.token && this.router.url.includes('/login')) {
        this.router.navigate(['dashboard'])
      }
    }, 0);
    this.autoLogOffService.isInactive.subscribe(value => {
      this.isInactive = value;
    })
  }

  checkToken(): void {
    setInterval(() => {
      this.token = localStorage.getItem('token');
      this.href = this.router.url;
      if (!this.token) {
        return;
      }
    }, 1);
  }
}
