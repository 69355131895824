import { UntypedFormControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginModel {

  constructor() { }

  mapDataToModel(data): {emailId: UntypedFormControl, password: UntypedFormControl} {
    const formData = ({
      emailId: (data && data.emailId) ? data.emailId : null,
      password: (data && data.password) ? data.password : null,
    });
    return this.getFormGroup(formData);
  }

  getFormGroup(data: any): {emailId: UntypedFormControl, password: UntypedFormControl} {
    return ({
      emailId: new UntypedFormControl(data.emailId, [Validators.required]),
      password: new UntypedFormControl(data.password, [Validators.required, Validators.maxLength(12), Validators.minLength(5)]),
    });
  }

  preparePayload(payload: any): any {
    return payload;
  }
}
