<div *ngIf='!token && publicVerified'>
    <app-email-verified-successfully *ngIf="router.url.includes('/emalVerified')"></app-email-verified-successfully>
</div>
<div *ngIf='!token && publicReset'>
    <app-reset-password></app-reset-password>
</div>

<div *ngIf="!token && public && !isInactive">
    <app-login></app-login>
</div>
<div *ngIf='!token && publicConformPassword'>
    <app-reset></app-reset>
</div>
<div *ngIf="!token && public && isInactive" style="overflow: hidden;">
    <app-inactive></app-inactive>
</div>

<div class="app-base-layout" *ngIf="token">
    <div class="row fullWidth">
        <div class="sidebarWidth w20" [ngClass]="{'mobile-view': mobileView, 'web-view': webView,
        'dashboard': router.url.includes('/dashboard')}">
            <app-sidebar></app-sidebar>
        </div>
        <div class="w-100 main-container-row w80" [ngClass]="{'mobile-view': mobileView, 'web-view': webView}">
            <app-loader></app-loader>
            <app-header></app-header>
            <app-main-container></app-main-container>
        </div>
        <div class="w-100 main-container-row w80 footer" [ngClass]="{'mobile-view': mobileView, 'web-view': webView}">
            <app-footer></app-footer>
        </div>
    </div>
</div>
<p-toast key="notification" position="top-right"></p-toast>