import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';

import { CustomerService } from '../client/employer-setup/customerService';
import { AppService } from '../service/app.service';
import { RandomServiceService } from '../service/random-service.service';
import { EmployeeNameService } from '../service/employee-name.service';
import { ContractorNameService } from '../service/contractor-name.service';
import { UserService } from '../user-management/user.service';
import { EmployeeService } from '../service/employee.service';
import { ClientService } from '../client/client.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  clientId: any;
  headerSub: any;
  pageData: any;
  getInfo: string;
  userId: string;
  firstName: string;
  employeeEmploy: string;
  contractorName: any;
  employeeName: string;
  employeeNameBasic: string;
  firstNameHead: string;
  message: any;
  employeeNameFixed: string;
  firstNameHeadValue: string;
  employeeId: string;
  userType: string;
  roleValueLogin: any;
  employeeDetail: string;
  clientDetail: string;

  constructor(
    readonly toast: ToastrService,
    readonly router: Router,
    readonly location: Location,
    readonly route: ActivatedRoute,
    readonly appService: AppService,
    readonly primengConfig: PrimeNGConfig,
    readonly customer: CustomerService,
    readonly employeeService: EmployeeNameService,
    readonly someserv: RandomServiceService,
    readonly userService: UserService,
    readonly contractorService: ContractorNameService,
    readonly empIdSer: EmployeeService,
    readonly clientService: ClientService
  ) {
    
   }

  ngOnInit(): void {
    this.roleValueLogin = JSON.parse(localStorage.getItem('roleValueLogin'));
    this.userType = localStorage.getItem('roleTypeName');
    this.empIdSer.telecast.subscribe(employeeId => this.employeeId = employeeId);
    this.employeeNameFixed = localStorage.getItem('selectEmployeeId');
    this.employeeService.telecast.subscribe(employeeNameFixed => this.employeeNameFixed = employeeNameFixed);
    this.employeeService.telecastEmpDetail.subscribe(employeeDetail => this.employeeDetail = employeeDetail)
    this.firstNameHead = localStorage.getItem('selectContractorId');
    this.contractorService.telecast.subscribe(firstNameHead => this.firstNameHead = firstNameHead);
    this.someserv.telecast.subscribe(message => this.message = message);
    const message = localStorage.getItem('businessName');
    if(message){
        this.someserv.editMsg(message);
    }
    // this.clientService.telecastClientDetail.subscribe(clientDetail => this.clientDetail = clientDetail)
    this.userInfo();
    if (window.location.href.indexOf('contractorPayroll') > -1) {
    }
    if (window.location.href.indexOf('contractorPayPayroll') > -1) {
    }
    if (window.location.href.indexOf('teamMembers/employee/employeePay') > -1) {
      setInterval(() => {
        this.getEmployeeName();
      }, 1000);
    }
    if (window.location.href.indexOf('teamMembers/employee/employeeBasics') > -1) {
      setInterval(() => {
        this.getEmployeeNameBasic();
      }, 1000);
    }
    if (window.location.href.indexOf('teamMembers/employee/employeeEmployment') > -1) {
      setInterval(() => {
        this.getEmployeeNameEmployment();
      }, 1000);
    }
  }

  logout(): void {
    this.userService.logout().subscribe((data: any) => {
    });
    this.removeLocalStorage();
    this.someserv.broadcastMessage('Slick Payroll');
    this.empIdSer.editId('0');
    this.employeeService.editMsg('Employee Name');
    this.contractorService.editMsg('Contractor Name');
    localStorage.clear();
    this.appService.toggleSideBar(false);
    this.router.navigate(['/login']);
  }

  removeLocalStorage(): void {
    localStorage.removeItem('employeeNameFixedBasic');
    localStorage.removeItem('employerSelectId');
    localStorage.removeItem('lastName');
    localStorage.removeItem('catalog');
    localStorage.removeItem('employeeNameFixedEmployment');
    localStorage.removeItem('userId');
    localStorage.removeItem('partyId');
    localStorage.removeItem('adminSecurity');
    localStorage.removeItem('firstName');
    localStorage.removeItem('resetToken');
    localStorage.removeItem('firstNameHead');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('businessName');
    localStorage.removeItem('selectEmployeeId');
    localStorage.removeItem('sideBarShowHide');
    localStorage.removeItem('editFlag');
    localStorage.removeItem('editSecondFlag');
    localStorage.removeItem('taxDetailsData');
    localStorage.removeItem('downloadMultiplePayroll');
    localStorage.removeItem('selectContractorId');
    localStorage.removeItem('roleValueLogin');
    localStorage.removeItem('employeLoginId');
    localStorage.removeItem('taxesAchFileValue');
    localStorage.removeItem('userType');
    localStorage.removeItem('setIdSave');
    localStorage.removeItem('taxesAchFileValue');
  }

  paySchedule(): void {
    this.router.navigate(['/employerSetup/payroll/pay-schedule']);
  }

  contributionInfo(): void {
    this.router.navigate(['/employerSetup/payroll/deduction-contribution']);
  }

  timeOffPolicy(): void {
    this.router.navigate(['/employerSetup/payroll/time-off-policies']);
  }

  userInfo(): void {
    this.getInfo = localStorage.getItem('token');
    this.userId = localStorage.getItem('userId');
    this.firstName = localStorage.getItem('firstName');
  }

  getHeaderInfo(): void {
    this.headerSub = this.appService.headerSource$.subscribe((page) => {
      this.pageData['name'] = page.name;
      this.pageData['count'] = page.count;
    });
  }

  getEmployeeNameBasic(): void {
    const name = localStorage.getItem('employeeNameFixedBasic');
    this.employeeNameBasic = name ? name : undefined;
  }

  editEmployee(): void {
    if(this.router.url == '/dashboard') return;
    if (+this.employeeId !== 0) {
      this.router.navigateByUrl('/employerSetup/allClient/employerSetup' + '?' + 'id' + '=' + this.employeeId + '&' + 'flag' + '=' + 'E');
    }
  }
  getEmployeeNameEmployment(): void {
    const name = localStorage.getItem('employeeNameFixedEmployment');
    this.employeeEmploy = name ? name : undefined;
  }

  getEmployeeName(): void {
    const name = localStorage.getItem('employeeNameFixed');
    this.employeeName = name ? name : undefined;
  }

  dashboard(): void {
    this.router.navigateByUrl('/dashboard');
  }
}
